import { defineStore } from 'pinia';
import { stopService } from '@/_services';


export const otherPickupListStore = defineStore('otherPickupList', {
  state: () => ({ 
    reload: false,
    otherPickups: null,
    error: null,
    otherPickupsAsExcel: null,
  }),
  actions: {
    getOtherPickups(pagination, filters, sort) {
      this.reload = false
      stopService.getAllOtherPickups(pagination, filters, sort)
        .then((results) => this.otherPickups = results)
        .catch((reason) => this.error = reason);
    },
    getOtherPickupsAsExcel(query, sort){
      query.courier= {'$ne': 'PROPIO'}
      query.type='RECOGIDA'
      stopService.getStopAsExcel(query, sort)
      .then((results) => this.otherPickupsAsExcel = results)
      .catch((reason) => this.error = reason);
    }
  },
})
