import createAxios from "./axiosHttp";

function getAllOwnPickups(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `stop/pickup/own/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}

function getAllOtherPickups(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `stop/pickup/others/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}
function getAllOwnDeliveries(pagination, query, sort) {
  const axios = createAxios();
  let urlPath = `stop/delivery/own/ql`;
  const ql = {
    query,
    pagination,
    sort,
  };
  return axios.post(urlPath, ql);
}
function getStopAsExcel(query, sort) {
  const axios = createAxios();
  let urlPath = `/stop/report/ql`;
  const ql = {
    query,
    sort,
  };
  return axios.post(urlPath, ql, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
function getStopById(stopId){
  const axios = createAxios();
  let urlPath = `stop/${stopId}`;
  return axios.get(urlPath);
}
function getStopShipments(stopId, pagination){
  const axios = createAxios();
  pagination.limit = 20;
  let urlPath = `stop/${stopId}/shipment`;
  return axios.get(urlPath, {
    params: pagination
  });
}
function getStopShipmentLabel(stopId) {
  const axios = createAxios();
  let urlPath = `stop/${stopId}/label`;
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
function getStopTracking(stopId) {
  const axios = createAxios();
  let urlPath = `stop/${stopId}/tracking`;
  return axios.get(urlPath);
}
function getStopShipmentAsExcel(stopId) {
  const axios = createAxios();
  let urlPath = `/stop/${stopId}/report/ql`;
  return axios.post(urlPath,{}, {
    responseType: "blob",
  }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function updateStopStatus(id, body) {
  const axios = createAxios();
  let urlPath = `stop/${id}/event`;
  return axios.put(urlPath, body);
}
function getStopManifest(stopId){
  const axios = createAxios();
  let urlPath = `stop/${stopId}/manifest`;
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
export const stopService = {
  getAllOwnPickups,
  getAllOtherPickups,
  getAllOwnDeliveries,
  getStopAsExcel,
  getStopById,
  getStopShipments,
  getStopShipmentLabel,
  getStopTracking,
  getStopShipmentAsExcel,
  updateStopStatus,
  getStopManifest
};
