<script setup>
import { onMounted, watch, ref, inject, computed } from "vue";
import { ArgonLoadingButton } from "@/components"
import Prestaciones from "./Prestaciones.vue";
import Tracking from "@/components/Tracking.vue";
import Bultos from "@/components/forms/Bultos.vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ValuationsSubTable from "@/components/ValuationsSubTable.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonDetail from "@/components/detail/ArgonDetailLayout";
import StatusBadge from "@/components/badges/ShipmentStatus.vue";
import LogisticsForm from "@/components/forms/LogisticsForm.vue";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { userStore } from "@/_store/userStore";
import { useRoute, useRouter } from "vue-router";
import { dateUtils, objectUtils } from "@/utils";
import { shipmentDetailStore, shipmentListStore } from "@/_store";
import { valuationStore as valuationStoreRequest} from "@/_store/valuationStore";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import FulfillmentShipment from "@/components/FulFillmentShipment.vue";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import propio from "@/assets/img/logos/logo.svg";
import { useStore } from "vuex";
import ModalChangeStatus from "./ModalChangeStatus.vue";
import ModalCalculateValuation from "./ModalCalculateValuation.vue";
import PackagesSorter from "./PackagesSorter.vue";

const route = useRoute();
const router = useRouter();
const userStores = userStore();
const valuationStore = valuationStoreRequest();
const shipmentStore = shipmentDetailStore();
const shipmentsStore = shipmentListStore();
const sweetAlert = inject("$swal");
let tabSelected = ref("bultos");
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');
let customerIdResolver = ref(userStores.userSigned?.extraData.customerId);
const tabs = ref([
  { label: "Bultos", value: "bultos" },
  { label: "Prestaciones", value: "prestaciones" },
]);
let disableButton = ref(false);
const disableButtonValuation = ref(false);
let statusModal=null;
const store = useStore();
const bootstrap = store.state.bootstrap;

const baseShipment = {
  _id: "",
  origin: "BEEFRONT",
  reference: "",
  locator: "",
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  delivery: {
    date: "",
    contact: {
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    },
    address: {
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    },
    schedules: {
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    },
    observations: "",
    internacional: false,
    in_point: false,
    point_code: "",
    point_name: "",
  },
  pickup: {
    date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
    contact: {
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    },
    address: {
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    },
    schedules: {
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    },
    observations: "",
    internacional: false,
    in_point: false,
    point_code: "",
    point_name: "",
    number: "",
    with_pickup: true,
  },
  customer_id: '',
  customer: {
    code: "",
    contact: {
      name: ""
    },
  },
  department: "",
  packages: [],
  packages_sorter: [],
  service: "",
  internacional: false,
  status: "",
  courier: "",
  features: {
    refund_amount: 0,
    insurance: 0,
    delivery_on_saturday: false,
    returns: false
  },
  errors: [],
  status_description: "",
  metadata: null,
  uri_tracking: "",
};
const rules = {
  date: { required: helpers.withMessage("Fecha es requerido", required) },
  service: { required: helpers.withMessage("Servicio es requerido", required) },
  packages: {
    required: helpers.withMessage("Bultos es requerido", required),
  },
};
let localValue = ref(null);
const v$ = ref(useVuelidate(rules, localValue));
const disablesInputs = ref(false);
let minDate = ref("");
let maxDate = ref("");
const newDescription = ref(null);
const keyLabel =ref(0);
const beeImage = computed(() => isAgency.value ? propio_agency : propio);
const actions = ref([
  [
    { label: "Etiqueta", event: "onLabelPrint" },
  ],
  [
    { label: "Copiar", event: "onCopyShipment" },
    { label: "Devolver", event: "onReturnShipment" }
  ],
  [{ label: "Anular", event: "onCancel" }],
]);
const events = {
  onLabelPrint: getShipmentsLabel,
  onJustificantePrint: justificante,
  onCancel: cancelShipment,
  onCopyShipment: actionCopyShipment,
  onReturnShipment: actionReturnShipment,
  onChangeStatus: changeStatus
};
let showModalValuation= ref(false) ;
let showModalStatus= ref(false) ;
function changeStatus() {
  showModalStatus.value= true
  setTimeout(() => {
    statusModal = new bootstrap.Modal(document.getElementById("modalChangeShipmentStatus"), {
    keyboard: true,
    });
    statusModal.show();
  }, 500);
}

onMounted(() => {
  loadShipment();
  shipmentStore.getServices()
  minDate.value = dateUtils.formatDate(
    dateUtils.getToday(),
    "YYYY-MM-DD"
  );
  maxDate.value = dateUtils.formatDate(
    dateUtils.add(dateUtils.getToday(), "days", 15),
    "YYYY-MM-DD"
  );
  if(!shipmentStore.isNew){
    tabs.value.push({ label: "Seguimiento", value: "seguimiento" })
  }
  if(isAgency.value && !shipmentStore.isNew){
    tabs.value.push({ label: "Valoración", value: "valuations" })
  }
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

function findServiceByName(name) {
  return servicios.value?.find(service => service.name === name) || null;
}

function getDescription(value){
  if (!value || value === '') return null;
  const foundService = findServiceByName(value);
  return foundService ? foundService.description : null;
}

function changedSelectService(service){
  newDescription.value = getDescription(service);
  keyLabel.value++;
}

function getValuations(id){
  valuationStore.getValuationById(id);
}

function loadShipment() {
  if (route.params.id) {
    shipmentStore.getShipmentById(route.params.id);
    if(isAgency.value){
      getValuations(route.params.id);
      tabs.value.push({label: "Bultos Sorter", value: "bultos_sorter"})
    }
    shipmentStore.isNew = false;
  } else {
    shipmentStore.isNew = true;
    actions.value = [];
    localValue.value = baseShipment;
    if (!isAgency.value) {
      localValue.value.customer_id = customerIdResolver
      getContactPickupByDefect();
    }
  }
}
function actionReturnShipment() {
  copyShipment('return');
}
function actionCopyShipment() {
  copyShipment('copy');
}
function getContactPickupByDefect() {
  shipmentStore.getContactByDefault(localValue.value.customer_id);
}

function saveItem() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    disableButton.value = true;

    formatAndCreateShipment('shipment');
  });
}

function calculateValuation() {
  const promise = validate();

  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    disableButtonValuation.value = true;
    formatAndCreateShipment('valuation');
  });
}

function formatAndCreateShipment(type) {
  const contract = objectUtils.copyByValue(localValue.value);
  if (shipmentStore.isNew) delete contract._id;
  contract.pickup.date = dateUtils.add(contract.date, "hours", 12);
  delete contract.date;

  if (
    typeof contract.features.insurance === "string" ||
    contract.features.insurance instanceof String
  )
    contract.features.insurance = 0;
  if (
    typeof contract.features.refund_amount === "string" ||
    contract.features.refund_amount instanceof String
  )
    contract.features.refund_amount = 0;

  if(type==='shipment'){
    shipmentStore.createShipment(contract);
  }
  else{
    shipmentStore.calculateValuation(contract);
  }
}

function cancelShipment() {
  sweetAlert({
    title: "¿Está seguro?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: "Cerrar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-primary`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      shipmentStore.cancelShipment(route.params.id);
    }
  });
}

function getShipmentsLabel() {
  shipmentStore.getShipmentLabel([route.params.id]);
}

function justificante() {
  shipmentStore.getJustificante(route.params.id);
}

function makeExport(blob) {
  let URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function loadPdfReceipt(value){
  const binaryString= atob(value);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i=0; i< len; i++){
    bytes[i]=binaryString.charCodeAt(i)
  }
  makeExport(new Blob([bytes], {type: 'application/pdf'}))
}
function changedState(state){
  showModalStatus.value= false
  localValue.value.status=state;
}
const keyCard = ref(0)
function resetTabs(){
  tabs.value =[
    { label: "Bultos", value: "bultos" },
    { label: "Prestaciones", value: "prestaciones" },
  ];
  tabSelected.value="bultos";
}
function copyShipment(typeAction) {
  resetTabs()
  const infoByValue = JSON.parse(JSON.stringify(localValue.value));
  infoByValue._id="";
  infoByValue.origin = 'BEEFRONT'
  infoByValue.date = dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD");
  infoByValue.locator="";
  infoByValue.reference="";
  infoByValue.pickup.number="";
  infoByValue.delivery.number="";
  infoByValue.pickup.id=null;
  infoByValue.delivery.id=null;
  infoByValue.status="";
  infoByValue.courier="";
  //infoByValue.customer = null
  infoByValue.errors = []
  infoByValue.uri_tracking = []
  infoByValue.account = null
  infoByValue.metadata = null
  infoByValue.packages_sorter = []
  for (let n = 0; n <= infoByValue.packages.length - 1; n += 1) {
    infoByValue.packages[n].mac = "";
    infoByValue.packages[n].locator = "";
    infoByValue.packages[n].locator2 = "";
    infoByValue.packages[n].reference = "";
    infoByValue.packages[n].items = []
    infoByValue.packages[n].mac = ''
  }
  if (infoByValue.features){
    infoByValue.features.refund_amount = 0;
    infoByValue.features.insurance = 0;
    infoByValue.features.delivery_on_saturday = false;
    infoByValue.features.returns = false;
  }
  infoByValue.pickup.date = dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD");
  localValue.value = infoByValue;
  if (typeAction == 'return') {
    returnShipment();
  }
  disablesInputs.value = false;
  keyCard.value++;
}

function returnShipment() {
  const infoByValue = JSON.parse(JSON.stringify(localValue.value));
  if(infoByValue.delivery.in_point){
    localValue.value.delivery = infoByValue.pickup;
    localValue.value.pickup.address.city = "";
    localValue.value.pickup.address.postal_code = "";
    localValue.value.pickup.address.province = "";
    localValue.value.pickup.address.street = "";
  } else {
    localValue.value.pickup = infoByValue.delivery;
    localValue.value.delivery = infoByValue.pickup;
  }
}
function closeValuation(){
  showModalValuation.value=false;
}
const serviceCourier = computed(() => {
  if (!localValue.value.service) return '';

  const foundService = findServiceByName(localValue.value.service);
  if(!foundService) return '';
  return foundService.courier;
});

watch(
  () => shipmentStore.shipmentLabel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
let servicios = ref([]);
watch(
  () => shipmentStore.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val.map((servicio) => {
        let image = "";
        switch (servicio.courier) {
          case "CEX":
            image = cex;
            break;
          case "CTT":
            image = ctt;
            break;
          default:
            image = beeImage;
            break;
        }
        return {
          ...servicio,
          image,
        };
      });
      newDescription.value = getDescription(localValue.value?.service);
      keyLabel.value++;
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => localValue.value?.customer_id,
  (newVal) => {
    if (newVal) {
      if (!route.params.id) {
        getContactPickupByDefect()
      }
    }
  }
);

watch(
  () => shipmentStore.justificante,
  (value) => {
    if (value) {
      if(value.type == "pdf"){
        loadPdfReceipt(value.receipt)
      }
      else{
        const request = value.receipt;
        window.open(request, "_blank");
      }
    } else {
      snackbar.value = {
        title: "Error",
        message: "Ha ocurrido un error.",
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.updatedShipment,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Actualizado",
      //   message: "El envío ha sido actualizado",
      //   type: "success",
      //   color: "white",
      // };
      shipmentsStore.reload = true
      router.push("/shipments");
    }
  }
);

watch(
  () => shipmentStore.createdShipment,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Creado",
      //   message: "El envío ha sido creado",
      //   type: "success",
      //   color: "white",
      // };
      shipmentsStore.reload = true
      router.push("/shipments");
    }
  }
);

watch(
  () => shipmentStore.canceledShipment,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Anulado",
        message: "El envío anulado",
        type: "success",
        color: "white",
      };
      localValue.value.status = 'ANULADO';
      shipmentsStore.reload = true
    }
  }
);

let key = ref(1);
watch(
  () => shipmentStore.defaultContact,
  (value) => {
    if (!value) return null;
    localValue.value.pickup = objectUtils.deepCopy(baseShipment.pickup, value);
    key.value += 1;
  }
);

watch(
  () => shipmentStore.error,
  (value) => {
    if (value) {
      disableButton.value = false;
      disableButtonValuation.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.shipment,
  (value) => {
    if (value) {
      const val = objectUtils.deepCopy(baseShipment, value);
      val.date = dateUtils.formatDate(value.pickup.date, "YYYY-MM-DD");
      localValue.value = val;
      disablesInputs.value = localValue.value.status != "ERROR";
      if(localValue.value.courier == "PROPIO"){
        actions.value[2].push({label: "Transitar Estado", event: "onChangeStatus" })
      }
      if(localValue.value.courier !== "PROPIO"){
        actions.value[0].push({label: "Justificante", event: "onJustificantePrint" })
      }
      if(!isAgency.value && (localValue.value.origin === "SHOPIFY" || localValue.value.origin === "PRESTASHOP")){
        tabs.value.push({ label: "Fulfillment", value: "fulfillment" });
      }
      if(localValue.value.status == "ERROR"){
        resetTabs();
      }
    }
  }
);


let recogidaForm = ref(null);
let entregaForm = ref(null);
watch(
  () => valuationStore.error,
  (value) => {
    if (value) {
      disableButton.value = false;
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => shipmentStore.valuation,
  (value) => {
    if (value) {
      disableButtonValuation.value = false;
      showModalValuation.value = true;
    }
  }
);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <div class="card" :key="keyCard">
    <div class="card-body">
      <argon-detail
        v-if="localValue"
        :group-actions="actions"
        @click="triggerEvent"
      >
        <template #buttons>
          <argon-loading-button
            v-if="!disablesInputs"
            class="me-1 btn-sm bg-gradient-primary"
            color="primary"
            variant="gradient"
            icon="fa fa-save"
            :loading="disableButton"
            :label="disableButton ? 'Guardando...' : 'Guardar'"
            :disable-button="disableButton"
            @click="saveItem"
          >
          </argon-loading-button>
          <argon-loading-button
            v-if="isAgency"
            class="me-1 btn-sm"
            color="primary"
            variant="outline"
            icon="fa fa-calculator"
            :loading="disableButtonValuation"
            :label="disableButtonValuation ? 'Calculando valoración' : 'Calcular valoración'"
            :disable-button="disableButtonValuation"
            @click="calculateValuation"
          >
          </argon-loading-button>
        </template>
        <template #body>
          <div class="row">
            <div class="col-12">
              <div class="row spacing">
                <div v-if="isAgency" class="col-md-6 col-lg-5 col-sm-12">
                  <div class="card h-100">
                    <div class="card-header">Cliente</div>
                    <div class="card-body">
                      <customer-autocomplete
                        ref="customer"
                        v-model="localValue"
                        :readonly="disablesInputs"
                        :show-departments="true"
                      >
                      </customer-autocomplete>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!isAgency"
                  :class="isAgency ? 'col-lg-2' : 'col-lg-3'"
                >
                  <div class="card">
                    <div class="card-header">Departamentos</div>
                    <div class="card-body">
                      <customer-autocomplete
                        ref="customer"
                        v-model="localValue"
                        :readonly="disablesInputs"
                        :show-departments="true"
                        :show-clients="false"
                        :cliente-id="localValue._id"
                      >
                      </customer-autocomplete>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-sm-12"
                  :class="isAgency ? 'col-lg-5' : 'col-lg-6'"
                >
                  <div class="card">
                    <div class="card-header">Servicio</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-3 col-xl-6">
                          <div class="row">
                            <div class="col-6">
                              <label class="col-form-label required"
                                >Fecha recogida</label
                              >
                            </div>
                            <div class="col-6">
                              <argon-input
                                id="date"
                                v-model="localValue.date"
                                :is-required="true"
                                :error="v$.date.$error"
                                type="date"
                                :disabled="disablesInputs"
                                :min="minDate"
                                :max="maxDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-5 col-xl-6">
                          <div class="row">
                            <div class="col-4">
                              <label
                                :key="keyLabel"
                                v-tooltip
                                :data-bs-title="newDescription"
                                class="col-form-label required"
                                >Servicio
                                <sup
                                  v-if="localValue.service != ''"
                                  class="text-primary sup-question"
                                  >?</sup
                                ></label
                              >
                            </div>
                            <div class="col-8">
                              <argon-select
                                v-if="shipmentStore.services"
                                id="contact-type"
                                v-model="localValue.service"
                                name="contact-type"
                                :is-required="true"
                                :options="servicios"
                                :error="v$.service.$error"
                                name-value="name"
                                courier="courier"
                                name-label="name"
                                description="description"
                                filterable
                                @selectedChange="changedSelectService"
                                :disabled="disablesInputs"
                                :show-icon="true"
                              >
                              </argon-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="isAgency ? 'col-lg-2' : 'col-lg-3'">
                  <div class="card">
                    <div class="card-header">Localizador</div>
                    <div class="card-body">
                      <argon-input
                        id="locator"
                        :placeholder="'Localizador'"
                        v-model="localValue.locator"
                        type="text"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
                  <div class="card">
                    <div class="card-header">Recogida</div>
                    <div class="card-body">
                      <logistics-form
                        ref="recogidaForm"
                        :key="key"
                        v-model="localValue.pickup"
                        name="RECOGIDA"
                        :readonly="disablesInputs"
                        :customer-id="localValue.customer_id"
                        :is-pickup="true"
                      >
                      </logistics-form>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
                  <div class="card">
                    <div class="card-header">Entrega</div>
                    <div class="card-body">
                      <logistics-form
                        ref="entregaForm"
                        v-model="localValue.delivery"
                        name="ENTREGA"
                        :readonly="disablesInputs"
                        :customer-id="localValue.customer_id"
                      >
                      </logistics-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #title>
          <div class="row">
            <div class="col-12">
              <argon-input
                id="reference"
                v-model="localValue.reference"
                :max-length="30"
                type="text"
                placeholder="Referencia"
                size="lg"
                font-style="h4 fw-bold"
                :disabled="disablesInputs"
              />
            </div>
            <!-- <div class="col-3">
              <argon-input class="float-right" id="locator" font-style="h4 fw-bold" v-model="localValue.locator" placeholder="Localizador" type="text" :disabled="true" />
            </div> -->
          </div>
        </template>
        <template #state>
          <partner-avatar :item="localValue.courier"></partner-avatar>
          <status-badge
            v-if="localValue.status_description"
            :item="localValue.status"
            :tooltip="localValue.status_description"
          ></status-badge>
          <status-badge
            v-else
            :item="localValue.status"
            :tooltip="localValue.errors"
          ></status-badge>
        </template>
        <template #tabs>
          <div class="card mt-3">
            <div class="card-body">
              <tabs-bootstrap
                v-model="tabSelected"
                :tabs="tabs"
              ></tabs-bootstrap>
              <prestaciones
                v-show="tabSelected == 'prestaciones'"
                :service-courier="serviceCourier"
                v-model="localValue.features"
                :readonly="disablesInputs"
                :courier="localValue.courier"
              />
              <bultos
                v-if="tabSelected == 'bultos'"
                v-model="localValue.packages"
                :readonly="disablesInputs"
              />
              <tracking
                v-if="tabSelected == 'seguimiento'"
                :id-shipment="route.params.id"
                :url="localValue.uri_tracking"
                :courier="localValue.courier"
              />
              <fulfillment-shipment
                :fulfillments="localValue.packages"
                v-if="
                  tabSelected === 'fulfillment' &&
                  localValue.packages.length > 0
                "
              />
              <valuations-sub-table v-if="tabSelected === 'valuations'" v-model="valuationStore.valuation" :header="false"/>
              <packages-sorter :packages-sorter="localValue.packages_sorter" v-if="tabSelected === 'bultos_sorter'"/>
            </div>
          </div>
        </template>
      </argon-detail>
    </div>
  </div>
  <modal-change-status v-if="showModalStatus" id="modalChangeShipmentStatus" :shipment-id="route.params.id" @success-change="changedState"/>
  <modal-calculate-valuation v-if="showModalValuation" id="modalValuation" @close="closeValuation"/>

  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
.sup-question {
  font-size: 0.9em;
}
</style>