<script setup>
import { onMounted, watch, ref, computed } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import StatusBadge from "@/components/badges/StopStatus.vue";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { useRouter } from "vue-router";
import { userStore } from "@/_store/userStore";
import { ownDeliveryListStore } from "@/_store/stopOwnDeliveryStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { estados } from "@/maestros/stopStatus";

import moment from "moment";

const snackbar = ref(null);
const fieldFilters = ref([
  {
    field: "customer.code",
    description: "Cliente Código",
  },
  {
    field: "customer.contact.name",
    description: "Cliente Nombre",
  },
  {
    field: "number",
    description: "Número",
  },
  {
    field: "address_text",
    description: "Dirección",
  }
]);
const ownDeliveriesStore= ownDeliveryListStore()
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: estados,
  },
  {
    field: "priority",
    label: "Prioridad",
    multi: true,
    options: [
      { label: "Prioridad Alta", value: 3 },
      { label: "Prioridad Media", value: 2 },
      { label: "Prioridad Baja", value: 1 },
    ],
  }
];


const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();

const columns = ref([ 
  {
    header: "Fecha",
    field: "date",
    width: "w-7",
    sort: true,
  },
  {
    header: "Prioridad",
    field: "priority",
    position: "center",
    sort: true,
  },
  {
    header: "Cliente",
    field: "customer.contact.name",
    sort: false,
  },
  {
    header: "Número",
    field: "number",
    sort: false,
  },
  {
    header: "Dirección",
    field: "address_text",
    sort: false,
  },
  {
    header: "Horario",
    field: "schedules_text",
    sort: false,
  },
    {
    header: "Partner",
    field: "courier",
    sort: false,
    position: "center",
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    position: "center",
    width: "w-10",
  },
]);
onMounted(() => {
    tableStore.clear();
    const ownDeliveryFilters = userStores.filtersPage.stopOwnDeliveries?.filters || [];
    const ownDeliveryFiltersFavorite = userStores.filtersPage.stopOwnDeliveries?.filtersFavorite || [];

    tableStore.changeFilters(ownDeliveryFilters);
    tableStore.addFavorite(ownDeliveryFiltersFavorite);
    if (reload.value) {
    setTimeout(() => {
        reloadPage()
    }, 700)
    }
    reloadPage();
});

function reloadPage() {
  userStores.filtersPage.stopOwnDeliveries = {
    filters: tableStore.filters,
    filtersFavorite: tableStore.filtersFavorite,
  };
  ownDeliveriesStore.getOwnDeliveries(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function onClick(val) {
  const url = `/delivery/own/${val._id}`;
  router.push(url);
}
function exportData() {
  ownDeliveriesStore.getOwnDeliveriesAsExcel(tableStore.query, tableStore.sort);
}

function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function getPriority(priority) {
  if (priority === undefined){
    return''
  }
  switch (priority) {
    case 3:
      return {icono:'fa fa-arrow-up text-xxs', tooltip:"Alta"};
    case 2:
      return {icono:'fa fa-arrow-right text-xxs', tooltip:"Media"};
    case 1:
      return {icono:'fa fa-arrow-down text-xxs', tooltip:"Baja"};
    default:
      return {icono:'', tooltip:''};
  }
}
watch(
  () => ownDeliveriesStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => ownDeliveriesStore.ownDeliverIEsAsExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);

const closeSnackbar = () => {
  snackbar.value = null;
};

const reload = computed({
  get() {
    return ownDeliveriesStore.reload
  },
})
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="ownDeliveriesStore.ownDeliveries"
        :columns="columns"
        :items="ownDeliveriesStore.ownDeliveries"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :show-importar="false"
        :show-check-input="false"
        :show-new-button="false"
        @on-click="onClick"
        @filter-changed="reloadPage"
        @page-changed="reloadPage"
        @sort-changed="reloadPage"
        @on-reload="reloadPage"
        @export-data="exportData"
      >
        <template #default="{ item }"> 
          <argon-td
            :position="getPosition('date')"
            :text-secondary="moment(item.date).format('DD/MM/YYYY')"
          ></argon-td>
          <argon-td  v-tooltip :data-bs-title="getPriority(item.priority).tooltip" :position="getPosition('priority')">
            <i v-if="item.priority != undefined" :class="getPriority(item.priority).icono"></i>
          </argon-td>
          <argon-td
            :position="getPosition('customer.contact.name')"
            :text-primary="item.customer.code"
            :text-secondary="item.customer.contact.name"
          ></argon-td>  
          <argon-td
            :position="getPosition('number')"
            :text-secondary="item.number"
          ></argon-td>
          <argon-td
            :position="getPosition('address_text')"
            :text-secondary="item.address_text"
            max-width="130px"
          ></argon-td>
          <argon-td
            :position="getPosition('schedules_text')"
            :text-secondary="item.schedules_text"
          ></argon-td>
          <argon-td :position="getPosition('courier')">
            <partner-avatar
              :item="item.courier"
            ></partner-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge
            v-if="item && item.errors && item.errors.length > 0"
            class="badge badge-md"
            :item="item.status"
            :tooltip="item.errors"
          ></status-badge>
          <status-badge
            v-else
            class="badge badge-md"
            :item="item.status"
            :tooltip="item.status_description"
          ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>