<script setup>
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import propio_agency from "@/assets/img/logos/logo-agency.svg";
import propio from "@/assets/img/logos/logo.svg";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { dateUtils } from "@/utils";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { userStore } from "@/_store/userStore";
import { pricingConceptsListStore } from "@/_store/pricingConceptsStore";
import { ref, onMounted, computed, watch } from "vue";
import { ArgonLoadingButton } from "@/components";
import { useStore } from "vuex";

const emits = defineEmits(["close", "success"]);
const pricingStore = pricingConceptsListStore();
const store = useStore();
const bootstrap = store.state.bootstrap;
const newConceptModal = ref(null);
const snackbar = ref(null);
const listConcept = ref([]);
const loading = ref(false);
const customerRef = ref(null);
const concept = ref(null);
const userStores = userStore();
const customerModel = ref({
  customer_id: "",
  customer: {
    contact: {
      name: "",
    },
  },
});
const rules = {
  concept_name: {
    required: helpers.withMessage("Suplemento es requerido", required),
  },
  from_date: { required: helpers.withMessage("Fecha es requerido", required) },
  value: { required: helpers.withMessage("El precio es requerido", required) },
};
const data = ref({
  concept_name: "",
  customer_id: "",
  from_date: "",
  value:"",
  min_commission: "",
  max_commission: "",
  courier: "",
});

const v$ = ref(useVuelidate(rules, data));

onMounted(() => {
  getConceptList();
  let myModal = new bootstrap.Modal(
    document.getElementById("modalNewConcept"),
    {
      keyboard: true,
    }
  );
  myModal.show();
});

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

function getConceptList() {
  pricingStore.getConceptList();
}

async function createConcept() {
  const promise = validate();
  let message = "";
  Promise.all([promise]).then((values) => {
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    loading.value = true;
    pricingStore.createConcept(getBody(data.value));
  });
}
function getBody(data) {
  let body = data;
  body.customer_id = customerModel.value.customer_id;
  body.courier = concept.value?.courier;
  body.from_date = dateUtils.add(body.from_date, "hours", 12);
  body.value = parseFloat(body.value);
  body.min_commission = parseFloat(body.min_commission);
  body.max_commission = parseFloat(body.max_commission);
  return body;
}
function findByName(name) {
  return listConcept.value?.find((concept) => concept.name === name) || null;
}
function selectedConcept(selected){
  concept.value=findByName(selected);
}
function getLabelPrice(){
  if(concept.value.is_percentage) return '% fijo';
  return 'Precio €';
}
function closeModal() {
  emits("close");
}

const closeSnackbar = () => {
  snackbar.value = null;
};

const isAgency = computed(() => userStores.userSigned?.portalType === "AGENCY");
const beeImage = computed(() => (isAgency.value ? propio_agency : propio));

watch(
  () => pricingStore.conceptsList,
  (val) => {
    if (val && val.length > 0) {
      listConcept.value = val.map((concept) => {
        let image = "";
        switch (concept.courier) {
          case "CEX":
            image = cex;
            break;
          case "CTT":
            image = ctt;
            break;
          default:
            image = beeImage;
            break;
        }
        return {
          ...concept,
          image,
        };
      });
    } else {
      listConcept.value = [];
    }
  }
);
watch(
  () => pricingStore.create,
  (value) => {
    if (value) {
      setTimeout(()=> {
        loading.value = false;
        newConceptModal.value.closeModal();
        emits("success");
        }, 1100);
          snackbar.value = {
            title: "Actualizado",
            message: "Creado correctamente",
            type: "info",
            color: "white",
          };
    }
  }
);
watch(
  () => pricingStore.createError,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
      loading.value = false;
    }
  }
);
</script>

<template>
  <modal-base id="modalNewConcept" ref="newConceptModal" @hide="closeModal">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">Nuevo</h6>
    </template>
    <template #body>
      <div class="row">
        <div class="col-4">
          <label for="inputPassword6" class="col-form-label"
            >Fecha vigencia</label
          >
        </div>
        <div class="col">
          <argon-input
            id="fecha"
            v-model="data.from_date"
            type="date"
            :error="v$.from_date.$error"
            placeholder="Seleccione la fecha"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="inputPassword6" class="col-form-label">Suplemento</label>
        </div>
        <div class="col">
          <argon-select
            v-if="listConcept.length > 0"
            id="concept-select"
            v-model="data.concept_name"
            name="concept-select"
            :options="listConcept"
            :error="v$.concept_name.$error"
            filterable
            name-value="name"
            name-label="code,description"
            menu-width="500"
            @selectedChange="selectedConcept"
          >
          </argon-select>
        </div>
      </div>
      <div class="row">
        <customer-autocomplete
          ref="customerRef"
          v-model="customerModel"
          :show-departments="false"
          placeholder="Cliente"
        >
        </customer-autocomplete>
      </div>
      <div class="row" v-if="concept">
        <div class="col-4">
          <label for="inputPassword6" class="col-form-label"
            >{{ getLabelPrice() }}</label
          >
        </div>
        <div class="col">
          <argon-input
            id="packages"
            v-model="data.value"
            :error="v$.value.$error"
            :is-required="true"
            type="number"
          />
        </div>
      </div>
      <div class="row" v-if="concept && concept.min_commission">
        <div class="col-4">
          <label for="inputPassword6" class="col-form-label"
            >Comisión mínima</label
          >
        </div>
        <div class="col">
          <argon-input
            id="packages"
            v-model="data.min_commission"
            :is-required="true"
            type="number"
          />
        </div>
      </div>
      <div class="row" v-if="concept && concept.max_commission">
        <div class="col-4">
          <label for="inputPassword6" class="col-form-label"
            >Comisión máxima</label
          >
        </div>
        <div class="col">
          <argon-input
            id="packages"
            v-model="data.max_commission"
            :is-required="true"
            type="number"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <argon-loading-button
        class="btn bg-gradient-primary btn-sm"
        color="primary"
        variant="gradient"
        :label="loading ? 'Creando...' : 'Crear'"
        :loading="loading"
        :disable-button="loading"
        @click="createConcept"
      />
    </template>
    <div class="position-fixed top-0 end-0 z-index-2 p-3">
      <argon-snackbar
        v-if="snackbar"
        :title="snackbar.title"
        :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
        :color="snackbar.color"
        :close-handler="closeSnackbar"
      />
    </div>
  </modal-base>
</template>
