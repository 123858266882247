<!-- eslint-disable no-unused-vars -->
<script setup>
import { useStore } from "vuex";
import { onMounted, watch, computed, ref } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import FulfillmentModal from './FulfillmentModal.vue'
import { orderListStore } from "@/_store/ordersStore";
import { userStore } from "@/_store/userStore";
import { shipmentDetailStore } from "@/_store";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import StatusBadge from "@/components/badges/OrderStatus.vue";
import moment from "moment";
import ArgonTd from "@/components/table/ArgonTd.vue";
import MarketplaceAvatar from "@/components/MarketplaceAvatar.vue";

const store = useStore();
const bootstrap = store.state.bootstrap;
const shipmentStore = shipmentDetailStore();

const fieldFilters = [
  {
    field: "number",
    description: "número",
  },
  {
    field: "customer.contact.name",
    description: "Cliente",
  },
  {
    field: "products.product_name",
    description: "Producto",
  },
  {
    field: "products.variant_ean",
    description: "Ean",
  },
  {
    field: "products.variant_sku",
    description: "Sku",
  },
];
const defaultFilters = [
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Pendiente", value: "PENDIENTE_PREPARAR" },
      { label: "Preparado parcial", value: "PREPARADO_PARCIAL" },
      { label: "Preparado", value: "PREPARADO" },
      { label: "Anulado", value: "ANULADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
  {
    field: "marketplace",
    label: "Marketplace",
    multi: false,
    options: [
        { label: "Shopify", value: "SHOPIFY" },
        { label: "Prestashop", value: "PRESTASHOP" },
    ],
  },
];
const columns = [
  {
    header: "Fecha",
    field: "date",
    sort: true,
  },
  {
    header: "Número",
    field: "number",
    sort: true,
    position: "start"
  },
  {
    header: "Cliente",
    field: "customer.contact.name",
    sort: false,
  },
  {
    header: "Dirección",
    field: "customer.address.text",
    sort: false,
  },
  {
    header: "Marketplace",
    field: "marketplace",
    position: "center",
    sort: false,
    width: "w-5",
  },
  {
    header: "Estado",
    field: "status",
    position: "center",
    sort: false,
    width: "w-10",
  },
];
const ordersStore = orderListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();
const modalFulfillment = ref(null)

onMounted(() => {
  tableStore.clear();
  tableStore.clearPagination();
  if (userStores.filtersPage.orders)
    tableStore.changeFilters(userStores.filtersPage.orders);
  else tableStore.changeFilters([]);
  reloadPage();
});
function reloadPage() {
  userStores.filtersPage.orders = tableStore.filters;
  ordersStore.getAllOrders(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function detailOrder(item) {
  router.push(`/marketplace/orders/${item._id}`);
}
function exportData() {
  ordersStore.getOrdersAsExcel(tableStore.query, tableStore.sort);
}
function makeExport(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
function getPosition(field) {
  const column = columns.find(col => col.field === field);
  return column && column.position ? column.position : 'start';
}

function showFulfillmentModal() {
  modalFulfillment.value = new bootstrap.Modal(document.getElementById("fulfillmentModal"), {
    keyboard: true,
  });
  //getContactPickupByDefect()
  modalFulfillment.value.show();
}
function getContactPickupByDefect() {
  shipmentStore.getContactByDefault();
}

function hideFulfillmentModal() {
  modalFulfillment.value.hide();
  reloadPage()
}
watch(
  () => ordersStore.ordersAsExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);

// eslint-disable-next-line no-unused-vars
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');

</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table v-if="ordersStore.orders" :show-new-button="false" :show-importar="false" :columns="columns"
        :items="ordersStore.orders" :field-filters="fieldFilters" :default-filters="defaultFilters"
        @filter-changed="reloadPage" @page-changed="reloadPage" @on-reload="reloadPage" @on-click="detailOrder"
        @sort-changed="reloadPage" @export-data="exportData">
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button id="navbarDropdownPrinter" color="primary" variant="outline"
              class="dropdown-toggle me-2 btn-sm" data-bs-toggle="dropdown"
              aria-expanded="false">Acciones</argon-button>
            <!-- <ul
                    class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
                    aria-labelledby="navbarDropdownPrinter"
                    style
                  >
                    <li>
                      <a
                        class="border-radius-md"
                        @click="showFulfillmentModal"
                        >Preparar</a
                      >
                    </li>
                  </ul> -->
            <ul class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter" style>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;" @click="showFulfillmentModal">Preparar</a>
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td :position="getPosition('date')" :text-primary="moment(item.date).format('DD/MM/YYYY')"
            :text-secondary="moment(item.date).fromNow()"></argon-td>
          <argon-td :text-primary="item.number" :position="getPosition('number')" />
          <argon-td :text-primary="item.customer.contact.name" :position="getPosition('name')" />
          <argon-td :text-primary="item.customer.address.text" :position="getPosition('text')" max-width="130px" />
          <argon-td :position="getPosition('marketplace')">
            <marketplace-avatar :item="item.marketplace"></marketplace-avatar>
          </argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge :item="item.status" :tooltip-message="item.error"></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
    <fulfillment-modal id="fulfillmentModal" @hide="hideFulfillmentModal"></fulfillment-modal>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}

.action-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.3rem 1rem;
  clear: both;
  font-weight: 400;
  color: #67748e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
</style>