<script setup>
import ArgonRow from "./ArgonRow.vue";
import ArgonHeader from "./ArgonHeader.vue";
import ArgonTableToolbar from "./ArgonTableToolbar.vue";
import { argonTableStore } from "@/_store/argonTableStore";
import { onMounted, ref, watch, computed } from "vue";
import { userStore } from "@/_store/userStore";
const emit = defineEmits([
  "filterChanged",
  "sortChanged",
  "pageChanged",
  "newItem",
  "onClick",
  "onReload",
  "exportData",
  "onSync",
  "eventAction"
]);
const tableStore = argonTableStore();
const userStores = userStore();
var checkedAll = ref(false);
var filters = ref([]);
const tableContainer = ref()
const genericTable = ref()

const props = defineProps({
  showNewButton: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  items: {
    type: Object,
    default() {
      return {
        total: 0,
        data: [],
      };
    },
  },
  moreActions: {
    type: Array,
    default() {
      return [];
    },
  },
  fieldFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  defaultFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  importOptions: {
    type: Object,
    default() {
      return {
        title: "Importar Fichero",
        options: [],
        url: "",
      };
    },
  },
  columns: {
    type: Array,
    default() {
      return [];
    },
  },
  showCheckInput: {
    type: Boolean,
    default: true,
  },
  showConfigOption: {
    type: Boolean,
    default: true,
  },
  showImportar: {
    type: Boolean,
    default: true,
  },
  showSyncButton: {
    type: Boolean,
    default: false,
  },
  tableHover: {
    type: Boolean,
    default: true,
  },
  labelButton: {
    type: String,
    default: "Nuevo",
  },
  showFavorites: {
    type: Boolean,
    default: true,
  },
});

watch(
  () => tableStore.hasSelectedItems,
  (newSelection) => {
    if (!newSelection) {
      checkedAll.value = false;
    }
  }
);
watch(
  () => tableStore.filters,
  () => {
    filters.value = tableStore.filters;
  }
);

watch(
  () => props.items,
  () => {
    displaySpinner()
  }
);
onMounted(() => {});

function selectAllPAge() {
  tableStore.toogleAllPage();
}

function filterChanged(changes) {
  displaySpinner(true)
  tableStore.changeFilters(changes);
  emit("filterChanged", changes);
}

function pageChanged(newPage) {
  displaySpinner(true)
  emit("pageChanged", newPage);
}

function sortChanged() {
  emit("sortChanged");
}
function importData() {}
function exportData() {
  emit("exportData");
}
function newItem() {
  emit("newItem");
}

function onClick(id) {
  emit("onClick", id);
}

function displaySpinner(show=false) {
  if (show) {
    let container = tableContainer.value
    let table = genericTable.value
    table.classList.add("overflow-hidden")
    container.classList.add('loading')
    container.style.setProperty('--size', '40px 40px');
    if (props.items.data.length <= 2 ) {
      container.style.setProperty('--top-position', '40%');
    }else{
      container.style.setProperty('--top-position', '0');
    }
    return
  }

  let container = tableContainer.value
    container.classList.remove('loading')


  let table = genericTable.value
  table.classList.remove("overflow-hidden")
}

function onReload() {
  displaySpinner(true)
  emit("onReload");
}

function onSync(shopType) {
  displaySpinner(true)
  emit("onSync", shopType);
}
function onEventAction(action){
  emit('eventAction', action)
}

const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY');
</script>
<template>
  <div id="argon-table-container" :class="isAgency? 'agency':'customer'" ref="tableContainer" class="p-0">
    <ArgonTableToolbar
      :show-new-button="showNewButton"
      :show-config-option="showConfigOption"
      :items="items.data"
      :field-filters="fieldFilters"
      :default-filters="defaultFilters"
      :total="items.total"
      :import-options="importOptions"
      :show-importar="showImportar"
      :show-sync-button="showSyncButton"
      :label-button="labelButton"
      @filter-changed="filterChanged"
      @page-changed="pageChanged"
      @import="importData"
      @export="exportData"
      @new-item="newItem"
      @on-reload="onReload"
      @on-sync="onSync"
      :showFavorites="showFavorites"
      :moreActions="moreActions"
      @event-action="onEventAction"
    >
      <template #buttons>
        <slot name="buttons"> </slot>
      </template>
    </ArgonTableToolbar>
    <div 
      id="argon-table"
      ref="genericTable" class="table-responsive">
      <table
        class="table align-items-center mb-0"
        :class="{ 'table-hover': tableHover }"
      >
        <thead class="sticky-top bg-white">
          <tr>
            <th class="font-weight-bolder opacity-7 ps-3 w-1">
              <div class="my-auto form-check">
                  <input
                    v-model="checkedAll"
                    :disabled="!showCheckInput"
                    type="checkbox"
                    class="form-check-input"
                    :class="!showCheckInput?'disabled':''"
                    @click="selectAllPAge()"
                  />
              </div>
            </th>
            <argon-header
              :headers="columns"
              @change-sort="sortChanged"
            ></argon-header>
          </tr>
        </thead>
        <tbody>
          <argon-row
            v-for="item in items.data"
            :key="item._id"
            :show-check-input="showCheckInput"
            :row-id="item._id"
            :table-hover="tableHover"
            @on-click="onClick(item)"
          >
            <slot :item="item"></slot>
          </argon-row>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scope>
#argon-table {
  max-height: 73vh;
}

.overflow-hidden {
  overflow: hidden;
}

#argon-table-container.loading #argon-table {
  position: relative;
}

#argon-table-container.agency.loading #argon-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-agency.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}
#argon-table-container.customer.loading #argon-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-customer.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}
</style>