<script setup>
defineProps({
  item: {
    type: String,
    default: "",
  },
  tooltip: {
    type: [String, Array],
    default: "",
  },
});

const colors = {
  PENDIENTE: "primary",
  EN_CURSO: "info",
  FINALIZADO: "success",
  INCIDENCIA: "danger-3",
  ANULADO: "danger-1",
};

function camelToNormal(text) {
  return text.replace("_", " ");
}

import ArgonBadge from "./../ArgonBadge";
</script>
<template>
  <argon-badge
    v-tooltip
    :data-bs-title="tooltip != '' ? tooltip : null"
    :color="colors[item]"
    variant="gradient"
    class="size"
    >{{ camelToNormal(item) }}</argon-badge
  >
</template>
<style scoped>
.size {
  min-width: 160px;
}
</style>