<script setup>
import { ref } from "vue";
import { onMounted, onBeforeUnmount, watch } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";

const emit = defineEmits(["update:modelValue"]);

import MultiOptions from "./MultiOptions.vue";



const props = defineProps({
  modelValue: {
    type: Array,
    default: ()=>{
      return []
    },
  },
  size: {
    type: String,
    default: "default",
  },
  icon: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "input-search",
  },
  placeholder: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fontStyle: {
    type: String,
    default: "",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
});
let localValue = ref(props.modelValue);
const suggestionRef = ref(null);
const index = ref(0);
const dropdownOpen = ref(false);
var searchText = ref();
var selectedFilters = ref([]);

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

function onRemoveOption(index){
  if(!props.disabled)
    localValue.value.splice(index,1);
}

const getClasses = (size, success, error) => {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;
  if (error) {
    isValidValue = "is-invalid";
  } else if (success) {
    isValidValue = "is-valid";
  } else {
    isValidValue = "";
  }

  return `${sizeValue} ${isValidValue}`;
};

function searchOnText(text){
  document.getElementById(props.id).focus();
  localValue.value.push(text);
  searchText.value="";
  emit("update:modelValue", localValue.value);
}

function handleKeydown(event) {
  const keyActions = {
    Enter: () => {
      if (index.value >= 0) suggestionRef.value?.click();
    },
    Backspace: () => {
      if (
        !searchText.value ||
        (searchText.value.length === 0 && selectedFilters.value.length > 0)
      ) {
        onRemoveOption(localValue.value.length - 1);
      }
    },
    Tab: () => {
      dropdownOpen.value=false;
      searchText.value="";
    }
  };

  const action = keyActions[event.key];
  if (action) {
    if (event.key !== "Backspace" && event.key !== "Tab") {
      event.preventDefault();
    }
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRef.value?.classList.remove("focused");
    }
    action();
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      suggestionRef.value?.classList.add("focused");
    }
  }
}
const handleClickOutside = (event) => {
  if (!event.target.closest(".dropdown")) {
      dropdownOpen.value = false;
      searchText.value = "";
  }
};
function focus(){
  document.getElementById(props.id+'-border-select').classList.add('border-focus')
  dropdownOpen.value = true;
}
function blur(){
  document.getElementById(props.id+'-border-select').classList.remove('border-focus')
}
watch(
  () => props.modelValue,
  (newValue) => {
    if (JSON.stringify(localValue.value) !== JSON.stringify(newValue)) {
      localValue.value = [...newValue];
    }
  },
  { immediate: true }
);

watch(
  () => localValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);
watch(searchText, (newVal) => {
  if (searchText.value != "") dropdownOpen.value = true;
  if (newVal && suggestionRef.value) {
    index.value = 0;
    suggestionRef.value.classList.add("focused");
  }
});

watch(dropdownOpen, () => {});
</script>
<template>
  <div
    class="col row border-0 dropdown pb-3"
    :aria-expanded="dropdownOpen"
  >
    <multi-options
      :disabled="disabled"
      v-for="(option, index) in localValue"
      :key="index"
      :text="option"
      :index="index"
      @remove-option="onRemoveOption"
    >
    </multi-options>
    <argon-input
      :id="id"
      v-model="searchText"
      :name="name"
      :placeholder="placeholder"
      type="input"
      class="col mb-0 input-select-multi ps-1"
      :class="getClasses(size, success, error)"
      @keydown="handleKeydown"
      @focus="focus"
      :disabled="disabled"
      @blur="blur"
    />
    <div
      v-if="dropdownOpen && searchText"
      class="multi-menu z-index-1030 border rounded p-1"
      aria-labelledby="searcherTextDropdown"
      style
    >
      <a
        ref="suggestionRef"
        class="dropdown-item border-radius-md"
        @click="searchOnText(searchText)"
        >{{ searchText }}</a
      >
    </div>
    <span :id="id+'-border-select'" class="border-input" :class="{'is-invalid': error}"></span>
  </div>
</template>
<style lang="scss">
.z-index-1030 {
  z-index: 1030 !important;
}

.filters {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.focused {
  color: #344767;
  background-color: #e9ecef;
}
.border-input{
  border-bottom: solid 1px #dee2e6;
  width: 98% !important;
  margin-left: 5px !important;
  transition: border-bottom 0.3s ease;
}
.input-select-multi {
  .form-control {
    border: none !important;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
  }
}
.is-invalid{
  border-bottom: solid 1px #f2385d !important;
}
</style>