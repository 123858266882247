<script setup>
import ArgonActions from "./ArgonActions.vue";
const emit = defineEmits(["click"]);

defineProps({
  groupActions: {
    type: Array,
    default() {
      return [];
    },
  },
});

function click(value) {
  emit("click", value);
}
</script>
<template>
  <div class="row">
    <div class="col-md-auto d-flex align-items-center">
      <slot name="buttons"></slot>
      <argon-actions
        class="ps-1"
        v-if="groupActions.length > 0"
        :group-actions="groupActions"
        @click="click"
      />
    </div>
    <div class="col d-flex justify-content-center">
      <nav class="d-flex justify-content-end align-items-center"></nav>
    </div>
    <div class="col-md-auto">
      <div class="pe-2"></div>
    </div>
  </div>
</template>
