<script setup>
import Shopify from "@/assets/img/small-logos/logo-shopify.svg";
import Prestashop from "@/assets/img/small-logos/logo-prestashop.webp";

defineProps({
  item: {
    type: String,
    default: "",
  },
});
import ArgonAvatar from "@/components/ArgonAvatar.vue";
const imagenCargada = {
  SHOPIFY: Shopify,
  PRESTASHOP: Prestashop
};
</script>
<template>
  <argon-avatar v-if="item" class="margin-icon" :image="imagenCargada[item]" v-tooltip :data-bs-title="item != '' ? item : null" size="xs" shadow="xs" />
</template>

<style scoped>
.margin-icon {
  padding-top: 3px;
  padding-bottom: 3px
}
.size {
  min-width: 20px;
  height: 28px;
}
</style>