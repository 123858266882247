<script setup>
import moment from "moment";
import { onMounted, watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { useUserStore } from "@/_store/userStore";
import { userTableStore } from "@/_store/userTableStore";

import { argonTableStore } from "@/_store/argonTableStore";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import NewUserModal from "./NewUserModal.vue";
import UserTypeBadge from "@/components/badges/UserType.vue";

const store = useStore();

var newUserModal = null;
const bootstrap = store.state.bootstrap;

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
const userStore = useUserStore();
const userListStore = userTableStore();

const tableStore = argonTableStore();
const isAgency = computed(() => userStore.userSigned?.portalType==='AGENCY');

const fieldFilters = [
  {
    field: "user",
    description: "Usuario",
  },
];
const columns = ref([
  {
    header: "Creado",
    field: "createdAt",
    sort: false,
    width: "w-15",
  },
  {
    header: "Usuario",
    field: "user",
    sort: false,
    width: "w-15",
  } 
]);
const defaultFilters = ref([
  {
      field: "status",
      label: "Estado",
      multi: false,
      options: [
        { label: "Activo", value: "ACTIVED" },
        { label: "Inactivo", value: "INACTIVED" },
      ],
  }
]);

onMounted(() => {
  if (isAgency.value){
    loadAgencyConfigs();
  }
  tableStore.clear();
  tableStore.clearPagination();
  if (userStore.filtersPage.users)
    tableStore.changeFilters(userStore.filtersPage.users);
  else tableStore.changeFilters([]);
  reloadPage();
});
function loadAgencyConfigs() {
  const newHeader = {
    header: "Tipo",
    field: "roles",
    position: "center",
    sort: false,
    width: "w-15",
  }
  columns.value.push(newHeader);
  const newFilters =   {
      field: "roles",
      label: "Tipo",
      multi: false,
      options: [
        { label: "Agencia", value: "AGENCY" },
        { label: "Cliente", value: "CUSTOMER" },  
      ],
  }
  defaultFilters.value.push(newFilters);
}
function reloadPage() {
  userStore.filtersPage.users = tableStore.filters;
  userListStore.getUsers(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}
function newUser() {
    newUserModal = new bootstrap.Modal(document.getElementById("newUserModal"), {
    keyboard: true,
  });
  newUserModal.show();
}
function getType(roles){
  if (roles.includes("AGENCY"))
    return "AGENCIA"
  else if (roles.includes("CUSTOMER"))
    return "CLIENTE"
  else return ''
}
watch(
  () => userListStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => userStore.newedUser,
  (value) => {
    if (value) {
      newUserModal.hide();
      reloadPage();
    }
  }
);
</script>
<template>
  <div class="card">
    <div class="card-body px-0 pt-0 pb-2 pt-2 ms-2">
      <argon-table
        v-if="userListStore.users"
        :show-config-option="false"
        :columns="columns"
        :field-filters="fieldFilters"
        :show-check-input="false"
        :items="userListStore.users"
        :table-hover="false"
        :default-filters="defaultFilters"
        @page-changed="reloadPage"
        @on-reload="reloadPage"
        @filter-changed="reloadPage"
        @new-item="newUser"
      >
        <template #default="{ item }">
          <argon-td
            :position="getPosition('createdAt')"
            :text-primary="moment(item.createdAt).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.createdAt).fromNow()"
          ></argon-td>
          <argon-td
            :position="getPosition('user')"
            :text-primary="item.user"
          ></argon-td>
          <argon-td v-if="isAgency"
            :position="getPosition('roles')"
          >
            <user-type-badge
              class="badge badge-md"
              :item="getType(item.roles)"
            ></user-type-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
    <NewUserModal id="newUserModal"></NewUserModal>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar"
      :title="snackbar.title"
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }"
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
</template>