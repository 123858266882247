<script setup>
const emit = defineEmits(["update:modelValue","focus","blur"]);

defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  iconDir: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 500,
  },
  label: {
    type: String,
    default: "",
  },
  fontStyle: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: String,
    default: null,
  },
  max: {
    type: String,
    default: null,
  },
});

const getClasses = (size, success, error, fontStyle) => {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;
  fontStyle = fontStyle ? fontStyle : null;
  if (error) {
    isValidValue = "is-invalid";
  } else if (success) {
    isValidValue = "is-valid";
  } else {
    isValidValue = "";
  }

  return `${sizeValue} ${isValidValue} ${fontStyle}`;
};
function openDatePicker(event) {
  event.target.showPicker();
}
function onFocus(){
  emit("focus");
}
function onBlur(){
  emit("blur");
}
const getIcon = (icon) => (icon ? icon : null);
const hasIcon = (icon) => (icon ? "input-group" : null);
</script>
<template>
  <div class="form-group">
    <p
      v-if="label != ''"
      class="fs-6 fw-bold"
      :class="{ required: isRequired }"
    >
      {{ label }}
    </p>
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, error, fontStyle)"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :isRequired="isRequired"
        :maxlength="maxLength"
        :disabled="disabled"
        :min="min"
        :max="max"
        @click="type === 'date' && openDatePicker($event)"
        @input="emit('update:modelValue', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>
<!-- <style>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
</style> -->
