<!-- eslint-disable no-unused-vars -->
<script setup>
import { objectUtils } from "@/utils";
import Direction from "@/components/forms/Direction.vue";
import Horario from "@/components/forms/Horario.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { required, helpers, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { ArgonLoadingButton } from "@/components"
import { shipmentDetailStore } from "@/_store";
import { computed, ref, watch, onMounted } from 'vue';
import PackageProductList from './PackageProductList.vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default() {
      return {
        items: [],
        mac: "",
        order: 1,
        length: 0,
        width: 0,
        height: 0
      }
    },
  },
  disableButton: {
    type: Boolean,
    default() {
      return false
    },
  },
  shop: {
    type: Object,
    default() {
      return {};
    },
  },
  order: {
    type: Object,
    default() {
      return {};
    },
  },
});

let key = ref(1);

const model = ref(props.modelValue)

onMounted(() => {
  model.value = props.modelValue;
  document.querySelector("#fulfillmentModal").addEventListener("show.bs.modal", (ev) => {
    model.value = props.modelValue
  });
});

const emit = defineEmits(["savePackageItem", 'removeProduct', "removeAllProducts"]);

const rules = {
  length: { 
    required: helpers.withMessage("Largo es requerido", required),
    minValue: helpers.withMessage("Largo debe ser mayor a 0", minValue(1))
  },
  width: {
    required: helpers.withMessage("Ancho es requerida", required),
    minValue: helpers.withMessage("Ancho debe ser mayor a 0", minValue(1))
  },
  height: {
    required: helpers.withMessage("Altura es requerida", required),
    minValue: helpers.withMessage("Altura debe ser mayor a 0", minValue(1))
  },
};

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const v$ = ref(useVuelidate(rules, model));

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

function savePackage() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }

    emit("savePackageItem")
    resetFields()
  });
}

function resetFields() {
  v$.value.length.$reset();
  v$.value.width.$reset();
  v$.value.height.$reset();
}

function removeProduct(product) {
  emit("removeProduct", product);
  }
  
function removeAllProducts(product) {
  emit("removeAllProducts", product);
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      model.value = props.modelValue
    }
  }
);

</script>
<template>
  <div class="row pb-0">
    <div class="col-4">
      <div class="row">
        <div class="col-5">
          <label for="col-form-label" class="col-form-label">Largo (cm)</label>
        </div>
        <div class="col-7">
          <argon-input
            id="length"
            v-model.number="model.length"
            :is-required="true"
            type="number"
            :error="v$.length.$error"
            min="1"
            placeholder="" />
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-5">
          <label for="col-form-label" class="col-form-label">Ancho (cm)</label>
        </div>
        <div class="col-7">
          <argon-input
            id="width"
            v-model.number="model.width"
            :is-required="true"
            :error="v$.width.$error"
            type="number"
            min="1"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <label for="col-form-label" class="col-form-label">Alto (cm)</label>
        </div>
        <div class="col-8">
          <argon-input
            id="height"
            v-model.number="model.height"
            :is-required="true"
            :error="v$.height.$error"
            min="1"
            type="number"
            placeholder=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row pb-0">
    <div class="col-lg-12 p-0 ps-2">
      <PackageProductList
        v-if="model.items.length>0"
        :items="model.items" 
        @remove-product="removeProduct"
        @remove-all-products="removeAllProducts"
      />
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar
      v-if="snackbar" 
      :title="snackbar.title" 
      :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" 
      :color="snackbar.color"
      :close-handler="closeSnackbar"
    />
  </div>
  <div class="row pt-0 pb-0">
    <div class="col-lg-12 text-end pt-0 pb-0">
      <argon-loading-button
        v-if="props.order.status!='PREPARADO' && props.order.status!='ANULADO' && props.order.status!='ERROR'"
        class="float-end"
        color="primary"
        variant="gradient"
        size="sm"
        :label="disableButton ? 'Cerrando...' : 'Cerrar bulto'"
        :loading="disableButton"
        :disable-button="disableButton"
        @click="savePackage"
      />
    </div>
  </div>
</template>
<style scoped>

</style>
