<script setup>
import { onMounted, watch, ref, computed } from "vue";
import ArgonTable from "@/components/table/ArgonTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import StatusBadge from "@/components/badges/ContactStatus.vue";
import TypeBadge from "@/components/badges/ContactType.vue";
import TiposImportaciones from "/src/maestros/TiposImportaciones.json";

import { userStore } from "@/_store/userStore";
import { contactListStore } from "@/_store/contactStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonTd from "@/components/table/ArgonTd.vue";
import moment from "moment";
const fieldFilters = ref([
  {
    field: "contact.name",
    description: "Nombre",
  },
  {
    field: "contact.attention_of",
    description: "Atención de",
  },
  {
    field: "address",
    description: "Dirección",
  },
]);
const defaultFilters = [
  {
    field: "type",
    label: "Tipo",
    multi: true,
    options: [
      { label: "Recogida", value: "RECOGIDA" },
      { label: "Entrega", value: "ENTREGA" },
    ],
  },
  {
    field: "status",
    label: "Estado",
    multi: true,
    options: [
      { label: "Creado", value: "CREADO" },
      { label: "Error", value: "ERROR" },
    ],
  },
  {
    field: "default",
    label: "Defecto",
    multi: false,
    options: [{ label: "Defecto", value: true }],
  },
];

const importOptions = {
  title: "Importar Contactos",
  options: TiposImportaciones.CONTACT.options,
  url: `/contact/batch`,
};

const contactStore = contactListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();

const columns = ref([
  {
    header: "Creado",
    field: "created_at",
    width: "w-10",
    sort: true,
    position: "start",
  },
  {
    header: "Tipo",
    field: "type",
    sort: false,
    position: "center",
  },
  {
    header: "Defecto",
    field: "default",
    sort: false,
    position: "center",
    width: "w-1",
  },
  {
    header: "Nombre",
    field: "contact.name",
    sort: false,
    position: "start",
  },
  {
    header: "Dirección",
    field: "address",
    sort: false,
    position: "start",
  },
  {
    header: "Estado",
    field: "status",
    sort: false,
    position: "center",
    width: "w-5",
  },
]);
onMounted(() => {
  if( userStores.userSigned.portalType==='AGENCY'){
    loadAgencyConfigs();
  }
  tableStore.clear();
  const filters = userStores.filtersPage.contacts?.filters || [];
  const filtersFavorite = userStores.filtersPage.contacts?.filtersFavorite || [];
  tableStore.changeFilters(filters);
  tableStore.addFavorite(filtersFavorite);

  if (reload.value) {
    setTimeout(() => {
      reloadPage()
    }, 1000)
  }

  reloadPage();
});
function loadAgencyConfigs() {
  const newHeader = {
    header: "Cliente",
    field: "customer.name", 
    sort: false,
    position: "start"
  }
  columns.value.splice(5, 0, newHeader);
  const newFilters = [
  {
    field: "customer.code",
    description: "Cliente Código",
  },
  {
    field: "customer.name",
    description: "Cliente Nombre",
  }]
  fieldFilters.value.push(...newFilters);
}
function reloadPage() {
  userStores.filtersPage.contacts={
    filters : tableStore.filters,
    filtersFavorite : tableStore.filtersFavorite ,
  }
  contactStore.getContacts(
    tableStore.pagination,
    tableStore.query,
    tableStore.sort
  );
}

function newContact() {
  const url = `/contacts/new`;
  router.push(url);
}

function exportData() {
  contactStore.getContactsAsExcel(tableStore.query, tableStore.sort);
}
function viewPDF(blob) {
  const URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}
watch(
  () => contactStore.contactsAsExcel,
  (newVal) => {
    if (newVal) {
      viewPDF(newVal);
    }
  }
);

function onClick(val) {
  const url = `/contacts/${val._id}`;
  router.push(url);
}
function getPosition(field) {
  const column = columns.value.find((col) => col.field === field);
  return column && column.position ? column.position : "start";
}

const reload = computed({
  get() {
    return contactStore.reload
  },
})
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-table
        v-if="contactStore.contacts"
        :columns="columns"
        :items="contactStore.contacts"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :import-options="importOptions"
        @filter-changed="reloadPage"
        @page-changed="reloadPage"
        @new-item="newContact"
        @on-reload="reloadPage"
        @on-click="onClick"
        @sort-changed="reloadPage"
        @export-data="exportData"
        :show-check-input="false"
      >
        <template #buttons>
          <div class="dropdown d-inline">
            <argon-button
              id="navbarDropdownPrinter"
              color="primary"
              variant="outline"
              class="dropdown-toggle me-2 btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-print me-sm-2"></i>Imprimir</argon-button
            >
            <ul
              class="dropdown-menu z-index-1030 dropdown-menu-lg-start px-2 py-3"
              aria-labelledby="navbarDropdownPrinter"
              style
            >
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Etiquetas</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Manifiesto</a
                >
              </li>
            </ul>
          </div>
        </template>
        <template #default="{ item }">
          <argon-td
            :position="getPosition('created_at')"
            :text-primary="moment(item.created_at).format('DD/MM/YYYY HH:mm')"
            :text-secondary="moment(item.created_at).fromNow()"
          ></argon-td>
          <argon-td :position="getPosition('type')">
            <type-badge class="badge badge-md" :item="item.type"></type-badge>
          </argon-td>
          <argon-td :position="getPosition('default')">
            <i v-if="item.default" class="fa fa-check text-xxs"></i>
          </argon-td>
          <argon-td
            :position="getPosition('contact.name')"
            :text-primary="item.contact.name"
            :text-secondary="item.contact.attention_of"
          ></argon-td>
          <argon-td
            :position="getPosition('address')"
            :text-secondary="item.address"
            max-width="130px"
          />
          <!-- <argon-td
            :position="getPosition('schedules')"
            :text-secondary="item.schedules"
          /> -->
          <argon-td
            v-if="userStores.userSigned.portalType==='AGENCY'"
            :position="getPosition('customer.name')"
            :text-primary="item.customer?.code"
            :text-secondary="item.customer?.name"
          ></argon-td>
          <argon-td :position="getPosition('status')">
            <status-badge
              :tooltip-message="item.error"
              :item="item.status"
            ></status-badge>
          </argon-td>
        </template>
      </argon-table>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.z-index-1030 {
  z-index: 1030 !important;
}
</style>