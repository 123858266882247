<script setup>
import ArgonTable from "@/components/table/ArgonTable.vue";
import { onMounted, watch } from "vue";
import { productListStore } from "@/_store/productsStore";
import { userStore } from "@/_store/userStore";
import { argonTableStore } from "@/_store/argonTableStore";
import { useRouter } from "vue-router";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import MarketplaceAvatar from "@/components/MarketplaceAvatar.vue";
import ArgonTd from "@/components/table/ArgonTd.vue";
import ProductsStatus from "@/components/badges/ProductsStatus.vue";
import NotFound from "@/assets/img/image.png";
import BadgeTagProducts from "@/components/badges/BadgeTagProducts.vue";

const fieldFilters = [
    {
        field: "name",
        description: "Nombre",
    },
    {
        field: "variant_ean",
        description: "Ean",
    },
    {
        field: "variant_sku",
        description: "Sku",
    },
    {
        field: "type",
        description: "Tipo",
    },
    {
        field: "variant_options",
        description: "Caracteristicas",
    },
];
const defaultFilters = [
    {
        field: "marketplace",
        label: "Marketplace",
        multi: false,
        options: [
            { label: "Shopify", value: "SHOPIFY" },
            { label: "Prestashop", value: "PRESTASHOP" },
        ],
    },
    {
        field: "status",
        label: "Estado",
        multi: true,
        options: [
            { label: 'Activo', value: "ACTIVO" },
            { label: 'Inactivo', value: "INACTIVO" },
            { label: 'Error', value: "ERROR" }
        ],
    },
];
const columns = [
    {
        header: "",
        field: "image",
        sort: false,
        width: "w-1",
    },
    {
        header: "Nombre",
        field: "name",
        sort: true,
        width: "w-10",
    },
    {
        header: "Ean",
        field: "variant_ean",
        sort: true
    },
    {
        header: "Sku",
        field: "variant_sku",
        sort: true
    },
    {
        header: "Tipo",
        field: "type",
        position: "start",
    },
    {
        header: "Caracteristicas",
        field: "variant_options",
        sort: false,
        position:"center",
        width: "w-30",
    },
    {
        header: "Marketplace",
        field: "marketplace",
        position: "center",
        sort: false
    },
    {
        header: "Estado",
        field: "status",
        sort: false,
        position: "center",
        width: "w-10",
    },
];
const productsStore = productListStore();
const userStores = userStore();
const tableStore = argonTableStore();
const router = useRouter();
onMounted(() => {
    tableStore.clear();
    tableStore.clearPagination();
    if (userStores.filtersPage.products)
        tableStore.changeFilters(userStores.filtersPage.products);
    else tableStore.changeFilters([]);
    reloadPage();
});
function reloadPage() {
    userStores.filtersPage.products = tableStore.filters;
    productsStore.getAllProducts(
        tableStore.pagination,
        tableStore.query,
        tableStore.sort
    );
}
function detailProduct(item) {
    router.push(`/marketplace/products/${item._id}`);
}
function exportData() {
    productsStore.getProductsAsExcel(tableStore.query, tableStore.sort);
}
function makeExport(blob) {
    const URI = URL.createObjectURL(blob);
    window.open(URI, "_blank");
}
function getPosition(field) {
    const column = columns.find(col => col.field === field);
    return column && column.position ? column.position : 'start';
}
watch(
    () => productsStore.productAsExcel,
    (newVal) => {
        if (newVal) {
            makeExport(newVal);
        }
    }
);
</script>
<template>
    <div class="card">
        <div class="card-body">
            <argon-table v-if="productsStore.products" :columns="columns" :field-filters="fieldFilters"
                :items="productsStore.products" @filter-changed="reloadPage" @page-changed="reloadPage"
                @on-reload="reloadPage" :showCheckInput="false" :show-new-button="false"
                :default-filters="defaultFilters" :show-importar="false" @on-click="detailProduct"
                @sort-changed="reloadPage" @export-data="exportData">
                <template #default="{ item }">
                    <argon-td :position="getPosition('product_name')">
                        <div class="d-flex align-items-center">
                            <argon-avatar class="p-0 me-2" :image="item.image || NotFound" size="mini" shadow="sm"
                                alt="Avatar" />
                            <p class="text-xs font-weight-bold mb-0">{{ item.product_name }}</p>
                        </div>
                    </argon-td>
                    <argon-td :text-primary="item.name" :position="getPosition('name')" />
                    <argon-td :text-primary="item.variant_ean" :position="getPosition('variant_ean')" />
                    <argon-td :text-primary="item.variant_sku" :position="getPosition('variant_sku')" />
                    <argon-td :text-primary="item.type" :position="getPosition('type')" />
                    <argon-td :position="getPosition('variant_options')">
                        <div v-if="item.variant_options.length > 0">
                            <BadgeTagProducts class="ms-1" v-for="data in item.variant_options" :key="data" :item="data" />
                        </div>
                    </argon-td>
                    <argon-td :position="getPosition('marketplace')">  
                        <marketplace-avatar
                        :item="item.marketplace"
                        ></marketplace-avatar>
                    </argon-td>
                    <argon-td :position="getPosition('status')">
                        <products-status :tooltip-message="item.error" :item="item.status" />
                    </argon-td>
                </template>
            </argon-table>
        </div>
    </div>
</template>