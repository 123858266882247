<script setup>
import { onMounted, watch, ref, inject, computed } from "vue";

import { ArgonLoadingButton } from "@/components"
import Horario from "@/components/forms/Horario.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox";
import Direction from "@/components/forms/Direction.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "../../../components/ArgonInput.vue";
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import StatusBadge from "@/components/badges/ContactStatus.vue";
import { useRoute, useRouter } from "vue-router";
import { contactDetailStore, contactListStore } from "@/_store";
import CustomerAutocomplete from "@/components/forms/CustomerAutoComplete.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { userStore } from "@/_store/userStore";

const route = useRoute();
const router = useRouter();
const sweetAlert = inject("$swal");
const contactStoreRequest = contactDetailStore();
const listStore = contactListStore()
const userStores = userStore();

const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');

const contactTypes = [
  { label: "Recogida", value: "RECOGIDA" },
  { label: "Entrega", value: "ENTREGA" },
];
const rules = {
  name: { required: helpers.withMessage("Nombre es requerido", required) },
  email: { email: helpers.withMessage("Ingrese un email valido", email) },
};

let disableButton = ref(false);
let contact = ref(null);
let localValue = ref(null);
let horarioForm = ref(null);
let directionForm = ref(null);
const v$ = ref(useVuelidate(rules, contact));

const actions = [[{ label: "Eliminar contacto", event: "onDelete" }]];
const events = {
  onDelete: deleteContact,
};
let key = ref(1);
onMounted(() => {
  loadContact();
  key.value += 1;
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}

function loadContact() {
  if (route.params.id) {
    contactStoreRequest.getContactById(route.params.id);
    contactStoreRequest.isNew = false;
  } else {
    contactStoreRequest.isNew = true;
    localValue.value = {
      contact: {
        name: "",
        vat_number: "",
        email: "",
        attention_of: "",
        phone: "",
      },
      reference: "",
      address: {
        postal_code: "",
        street: "",
        street2: "",
        city: "",
        country: "ES",
        location: {
          lat: 0,
          lng: 0,
        },
        text: "",
        place_id: "",
        normalized: true,
        province: "",
      },
      schedules: {
        first_frequency: {
          initial: "",
          final: "",
        },
        second_frequency: {
          initial: "",
          final: "",
        },
      },
      error: "",
      type: "RECOGIDA",
      default: false,
      customer_id: '',
      customer: {
        code: "",
        contact: {
          name: ""
        },
      },
    };
    contact.value = localValue.value.contact;
  }
}
function saveItem() {
  const promiseContact = validate();
  const promiseDirection = validateDirection();
  const promiseHorario = validateHorario();

  Promise.all([promiseContact, promiseDirection, promiseHorario]).then(
    (values) => {
      let message = "";
      for (const value of values) {
        for (const error of value) {
          if(!message.includes(`<li>${error.$message}</li>`))
            message = `${message}<li>${error.$message}</li>`;
        }
      }
      if (message != "") {
        snackbar.value = {
          title: "Error",
          message: `<ul>${message}</ul>`,
          type: "danger",
          color: "white",
        };
      } else {
        disableButton.value = true
        if (!contactStoreRequest.isNew)
          contactStoreRequest.updateContact(route.params.id, localValue.value);
        else {
          contactStoreRequest.insertContact(localValue.value);
        }
      }
    }
  );
}
async function validateHorario() {
  return await horarioForm.value.validate();
}

async function validateDirection() {
  return await directionForm.value.validate();
}
function deleteContact() {
  sweetAlert({
    title: "¿Está seguro?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: "Cerrar",
    reverseButtons: true,
    customClass: {
      confirmButton: `btn ms-1 bg-gradient-primary`,
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      contactStoreRequest.deleteContact(route.params.id);
    }
  });
}
watch(
  () => contactStoreRequest.deletedId,
  (value) => {
    if (value) {
      sweetAlert({
        title: "Borrado!",
        text: "Tu contacto ha sido borrado.",
        icon: "success",
        customClass: {
          confirmButton: `btn bg-gradient-success`,
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push("/contacts");
        }
      });
    }
  }
);

watch(
  () => contactStoreRequest.createdContact,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Creado",
      //   message: "El contacto ha sido creado",
      //   type: "success",
      //   color: "white",
      // };
      listStore.reload = true
      router.push("/contacts");
    }
  }
);

watch(
  () => contactStoreRequest.updatedContact,
  (value) => {
    if (value) {
      // snackbar.value = {
      //   title: "Actualizado",
      //   message: "El contacto ha sido actualizado",
      //   type: "success",
      //   color: "white",
      // };
      listStore.reload = true
      router.push("/contacts");
    }
  }
);
watch(
  () => contactStoreRequest.error,
  (value) => {
    if (value) {
      disableButton.value = false
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);
watch(
  () => contactStoreRequest.contact,
  (value) => {
    if (value) {
      localValue.value = value;
      contact.value = localValue.value.contact;
    }
  }
);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div v-if="localValue" class="card-body">
      <argon-detail-layout :group-actions="actions" @click="triggerEvent">
        <template #buttons>
          <argon-loading-button class="btn-sm me-1 bg-gradient-primary" color="primary" variant="gradient"
            icon="fa fa-save" :label="disableButton ? 'Guardando...' : 'Guardar'" :disable-button="disableButton"
            @click="saveItem">
          </argon-loading-button>
        </template>
        <template #body>
          <div class="row">
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing" v-if="isAgency">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">Cliente</div>
                    <div class="card-body">
                      <customer-autocomplete size="col-12" ref="customer" v-model="localValue"
                        :readonly="!contactStoreRequest.isNew" :show-departments='false'>
                      </customer-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      Contacto
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Referencia</label>
                        </div>
                        <div class="col-9">
                          <argon-input id="reference"
                            v-model="localValue.reference" :max-length="20" type="text"
                              />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Atención de</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="attention_of"
                            v-model="localValue.contact.attention_of" :max-length="40" type="text"
                              />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Id fiscal</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="text" v-model="localValue.contact.vat_number"
                            :max-length="25" type="vat_number"   />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label class="col-form-label">Email</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="email" v-model="localValue.contact.email"
                            :max-length="75" type="email"  :error="v$.email.$error" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label for="inputPassword6" class="col-form-label">Teléfono</label>
                        </div>
                        <div class="col-9">
                          <argon-input v-if="localValue.contact" id="phone" v-model="localValue.contact.phone"
                            :max-length="15" type="text"  />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">Tipo</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-3">
                              <label for="inputPassword6" class="col-form-label">Defecto</label>
                            </div>
                            <div class="col-9">
                              <argon-checkbox id="default" :key="key" v-model.number="localValue.default" name="actidefaultve"
                                class="ms-2"></argon-checkbox>
                              <!-- <argon-switch id="default" v-model="localValue.default" name="default" left-label="No"
                                >Sí</argon-switch> -->
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-3">
                              <label for="inputPassword6" class="col-form-label">Tipo</label>
                            </div>
                            <div class="col-9">
                              <argon-select id="contact-type" v-model="localValue.type" name="contact-type"
                                :is-required="true" :options="contactTypes">
                              </argon-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Dirección</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <direction ref="directionForm" v-model="localValue.address" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Horario</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <Horario ref="horarioForm" v-model="localValue.schedules" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #title>
          <argon-input v-if="localValue.contact" id="name" v-model="localValue.contact.name" :max-length="40"
            :is-required="true" :error="v$.name.$error" type="text" placeholder="Nombre" size="lg"
            font-style="h4 fw-bold" />
        </template>
        <template #state>
          <status-badge :item="localValue.status"></status-badge>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
