import { createAxios } from "./axiosMarketplaceHttp";
import { Shops } from "@/globalVars";
import { createAxios as createAxiosApi } from "./axiosHttp";

function getShop(id) {
  const axios = createAxiosApi();
  
  let urlPath = `marketplace/shop/${id}`;
  return axios.get(urlPath);
}

function getShopify() {
  const axios = createAxiosApi();
  
  let urlPath = `marketplace/${Shops.SHOPIFY}/shop`;
  return axios.get(urlPath);
}

function getPrestaShop() {
  const axios = createAxiosApi();
  
  let urlPath = `marketplace/PRESTASHOP/shop`;
  return axios.get(urlPath);
}

function createShop(body, shopType) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/${shopType}/shop`;
  return axios.post(urlPath, body);
}

function updateShop(id, body) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/shop/${id}`;
  return axios.put(urlPath, body);
}

function deactivateShop(id) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/shop/${id}`;
  return axios.delete(urlPath);
}

function syncShop(shopType) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/${shopType}/bulk/shop/`;
  return axios.post(urlPath, {});
}

function syncShopyById(shopId) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/shop/${shopId}/bulk/`;
  return axios.post(urlPath, {});
}

function pingShop(body, shopType) {
  const axios = createAxios();
  
  let urlPath = `api/marketplace/${shopType}/ping`;
  return axios.post(urlPath, body);
}

export const marketplaceService = {
  getShop,
  syncShop,
  createShop,
  updateShop,
  deactivateShop,
  getShopify,
  getPrestaShop,
  syncShopyById,
  pingShop
};
