<script setup>
import { onMounted, watch, ref, inject, computed } from "vue";
import { Shops } from "@/globalVars";
import ShopCheckConnection from './ShopCheckConnection.vue'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { ArgonLoadingButton } from "@/components"
import ArgonSelect from "@/components/ArgonSelect.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { syncListStore } from "@/_store/syncStore";
import img from "@/assets/img/small-logos/logo-prestashop.webp";
import ArgonSelectMulti from "@/components/multi/ArgonSelectMulti.vue";
import { prestashopStore as prestashopTypeStore, marketplaceStore } from "@/_store";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minValue } from "@vuelidate/validators";
import { objectUtils } from "@/utils";
import { userStore } from "@/_store/userStore";
import cex from "@/assets/img/logos/partners/cex.jpeg";
import ctt from "@/assets/img/logos/partners/ctt.png";
import propio from "@/assets/img/logos/logo.svg";
import propio_agency from "@/assets/img/logos/logo-agency.svg";

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};

const syncStore = syncListStore();
const userStores = userStore();
const disableButton = ref(false);
const disablePingButton = ref(false);
const disableSyncButton = ref(false);
const isLoadingShop = ref(false);
const sweetAlert = inject("$swal");
const prestashopStore = prestashopTypeStore();
const store = marketplaceStore();

const rules = {
  // shop_external_id: {
  //   required: helpers.withMessage(
  //     "Identificador de tienda externa es requerido",
  //     required
  //   ),
  // },
  // app_token: {
  //   required: helpers.withMessage("App token e requerido", required),
  // },
  secret: { required: helpers.withMessage("Secret es requerido", required) },
  urls: { required: helpers.withMessage("Urls son requeridas", required) },
  version: { required: helpers.withMessage("Versión es requerida", required) },
  international_service: {
    required: helpers.withMessage(
      "Servicio internacional es requerido",
      required
    ),
  },
  national_service: {
    required: helpers.withMessage(
      "Servicio nacional es requerido",
      required
    ),
  },
  default_package: {
    length: { 
      required: helpers.withMessage("Largo es requerido", required),
      minValue: helpers.withMessage("Largo debe ser mayor a 0", minValue(1))
    },
    width: {
      required: helpers.withMessage("Ancho es requerida", required),
      minValue: helpers.withMessage("Ancho debe ser mayor a 0", minValue(1))
    },
    height: {
      required: helpers.withMessage("Altura es requerida", required),
      minValue: helpers.withMessage("Altura debe ser mayor a 0", minValue(1))
    },
  }
};
let key = ref(0);
let dropdownExpanded = ref(false);
let readonly = ref(false);
let newDescription = ref(null);
let newDescriptionInternational = ref(null);
const keyLabel =ref(0);
let model = ref({
  _id: "",
  actived: false,
  shop_external_id: "",
  app_token: "",
  secret: "",
  urls: [],
  version: "v1",
  international_service: "",
  national_service: "",
  default_package: { length: 0, width: 0, height: 0 },
});
let v$ = ref(useVuelidate(rules, model));
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');
onMounted(() => {
  getShop();
  getServices();
});

function getDescription(value){
  if(!value || value === '') return null;
  const foundService = store.services?.find(service => service.name === value);
  if(foundService != undefined && foundService != null) return foundService.description;
  else return null;
}

function getShop() {
  isLoadingShop.value = true
  prestashopStore.getShop();
}

function getServices() {
  store.getServices();
}

function switchExpanded() {
  dropdownExpanded.value = !dropdownExpanded.value;
}

function setMode(value) {
  if (value && value.actived) {
    model.value = value;
    readonly.value = true;
    key.value += 1;
    return;
  }
  readonly.value = false;
}

function changeActive() {
  if (!readonly.value && dropdownExpanded.value) {
    dropdownExpanded.value = false;
    model.value.actived = false;
    key.value += 1;
    return
  }

  if (model.value.actived) confirmDeactivation();
  else active();
}

function active() {
  dropdownExpanded.value = true;
  model.value.actived = true;
  key.value += 1;
}

function confirmDeactivation() {
  sweetAlert({
    title: "¿Está seguro?",
    text: "Se eliminara la tienda de Prestashop",
    showCancelButton: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: "Cerrar",
    reverseButtons: true,
    customClass: {
      confirmButton: "btn bg-gradient-primary ms-1",
      cancelButton: "btn btn-outline-primary me-1",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      deactivate();
      model.value.actived = false;
      key.value += 1;
    } else {
      model.value.actived = true;
      key.value += 1;
    }
  });
}

function update() {
  disableButton.value = true
  const contract = formatContract();
  store.updateShop(model.value._id, contract);
}

function deactivate() {
  store.deactivateShop(model.value._id);
}

function syncShop() {
  disableSyncButton.value = true
  syncStore.syncShopById(model.value._id);
}

function create() {
  disableButton.value = true
  const contract = formatContract();
  store.createShop(contract, Shops.PRESTASHOP);
}

function saveItem() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    if (readonly.value) {
      update();
      return;
    }
    create();
  });
}

function pingStore() {
  const promise = validate();
  Promise.all([promise]).then((values) => {
    let message = "";
    for (const value of values) {
      for (const error of value) {
        message = `${message}<li>${error.$message}</li>`;
      }
    }
    if (message != "") {
      snackbar.value = {
        title: "Error",
        message: `<ul>${message}</ul>`,
        type: "danger",
        color: "white",
      };
      return;
    }
    const contract = formatContract();
    disablePingButton.value = true
    store.pingShop(contract, Shops.PRESTASHOP);
  });
}


function formatContract() {
  const contract = objectUtils.copyByValue(model.value);
  if (contract._id == "") delete contract._id;

  if (
    typeof contract.default_package.width === "string" ||
    contract.default_package.width instanceof String
  )
    contract.default_package.width = 0;
  if (
    typeof contract.default_package.height === "string" ||
    contract.default_package.height instanceof String
  )
    contract.default_package.height = 0;
  if (
    typeof contract.default_package.length === "string" ||
    contract.default_package.length instanceof String
  )
    contract.default_package.length = 0;
  return contract;
}

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}

const beeImage = computed(() => isAgency.value ? propio_agency : propio);

let servicios = ref([]);
watch(
  () => store.services,
  (val) => {
    if (val && val.length > 0) {
      servicios.value = val.map((servicio) => {
        let image = "";
        switch (servicio.courier) {
          case "CEX":
            image = cex;
            break;
          case "CTT":
            image = ctt;
            break;
          default:
            image = beeImage;
            break;
        }
        return {
          ...servicio,
          image,
        };
      });
    } else {
      servicios.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => prestashopStore.shop,
  (value) => {
    if (value) {
      isLoadingShop.value = false
      setMode(value);
    }
  }
);

watch(
  () => prestashopStore.error,
  (value) => {
    if (value) {
      isLoadingShop.value = false
    }
  }
);

watch(
  () => store.deactivateShopState,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Suscripción desactivada</ul>`,
        type: "info",
        color: "white",
      };
      setTimeout(() => {
        if (value.marketplace != Shops.PRESTASHOP) return
        getShop();
      }, 1000);
    }
  }
);

watch(
  () => store.updateShopState,
  (value) => {
    if (value) {
      disableButton.value = false
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Actualizada con éxito</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => store.createShopState,
  (value) => {
    if (value) {
      disableButton.value = false
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Suscripción activada</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => store.error,
  (value) => {
    if (value) {
      disableButton.value = false
      isLoadingShop.value = false
      disablePingButton.value = false
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => syncStore.error,
  (value) => {
    if (value) {
      disableSyncButton.value = false
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => syncStore.syncById,
  (value) => {
    if (value) {
      disableSyncButton.value = false
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Sincronización iniciada</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => store.pingShopState,
  (value) => {
    if (value) {
      disablePingButton.value = false
      snackbar.value = {
        title: "Actualizado",
        message: `<ul>Conexión correcta</ul>`,
        type: "info",
        color: "white",
      };
    }
  }
);

watch(
  () => model.value?.national_service,
  (newVal) => {
    if (newVal) {
      newDescription.value = getDescription(newVal);
      keyLabel.value += 1
    } else {
      newDescription.value = null;
    }
  }
);
watch(
  () => model.value?.international_service,
  (newVal) => {
    if (newVal) {
      newDescriptionInternational.value = getDescription(newVal);
      keyLabel.value += 1
    } else {
      newDescriptionInternational.value = null;
    }
  }
);

</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex mt-2">
        <argon-avatar class="width-48-px" :image="img" alt="logo_slack" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h6 class="mb-0">Prestashop</h6>
            <a class="text-sm text-body" href="javascript:;" @click="switchExpanded">
              {{ dropdownExpanded ? "Ver menos" : "Ver más" }}
              <i class="fas text-xs ms-1" :class="dropdownExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="ms-auto my-auto">
          <argon-switch v-if="!isLoadingShop" id="enable-shopify" :key="key" v-model="model.actived" name="Enabled" @click="changeActive" />
          <div v-if="isLoadingShop" class="spinner-border spinner-size me-sm-2 text-primary" role="status">
            <span class="visually-hidden fs-1">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-show="dropdownExpanded" v-if="model.actived">
    <div class="col-12">
      <div v-if="false" class="row">
        <div class="col-3">
          <label for="col-form-label" class="col-form-label">Identificador de tienda</label>
        </div>
        <div class="col-9">
          <argon-input
            id="prestashop_shop_external_id" v-model="model.shop_external_id" :is-required="true"
            placeholder="" name="" :disabled="readonly" :max-length="100"
            :error="v$.shop_external_id.$error" autocomplete="off" />
        </div>
      </div>
      <div v-if="false" class="row">
        <div class="col-3">
          <label for="col-form-label" class="col-form-label">Token</label>
        </div>
        <div class="col-9">
          <argon-input
            id="prestashop_app_token" v-model="model.app_token" :is-required="true" placeholder=""
            name="app_token" :disabled="readonly" :max-length="100" :error="v$.app_token.$error" autocomplete="off"/>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="col-form-label" class="col-form-label">Secret</label>
        </div>
        <div class="col-9">
          <argon-input
            id="prestashop_secret" v-model="model.secret" :is-required="true" placeholder="" type="password"
            name="secret" :max-length="100" :error="v$.secret.$error" autocomplete="off" />
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="col-form-label" class="col-form-label">Urls</label>
        </div>
        <div class="col-lg-9">
          <!-- <argon-input id="prestashop_url" v-model="model.url" :is-required="true" placeholder="" name="url"
            :disabled="readonly" :max-length="100" :error="v$.url.$error" autocomplete="off" /> -->
            <div class="container w-100 p-0">
              <argon-select-multi
              v-model="model.urls" :is-required="true" placeholder="" name="urls"
            :disabled="readonly" :max-length="100" :error="v$.urls.$error" autocomplete="off" />
            </div>
        </div>
      </div>      
      <div class="row">
        <div class="col-3">
          <label for="col-form-label" :key="keyLabel" v-tooltip :data-bs-title="newDescription" class="col-form-label">Servicio nacional<sup v-if="model.national_service != ''" class="text-primary sup-question">?</sup></label>
        </div>
        <div class="col-9">
          <argon-select v-if="store.services" id="prestashop_national_service" :key="key" v-model="model.national_service"
            name="national_service" :is-required="true" :options="servicios" name-value="name"
            label-value="name" filterable :error="v$.national_service.$error">
          </argon-select>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label for="col-form-label" :key="keyLabel" v-tooltip :data-bs-title="newDescriptionInternational" class="col-form-label">Servicio internacional<sup v-if="model.international_service != ''" class="text-primary sup-question">?</sup></label>
        </div>
        <div class="col-9">
          <argon-select v-if="store.services" id="prestashop_international_service" :key="key" v-model="model.international_service"
            name="international_service" :is-required="true" :options="servicios" name-value="name"
            label-value="name" filterable :error="v$.international_service.$error">
          </argon-select>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="col-6">
            <label for="col-form-label" class="col-form-label">Paquete por defecto</label>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row">

                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Largo (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input id="width" v-model.number="model.default_package.length" :is-required="true" 
            :error="v$.default_package.length.$error"
                    type="number" placeholder="" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Ancho (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input id="width" v-model.number="model.default_package.width" :is-required="true" 
            :error="v$.default_package.width.$error"
                    type="number" placeholder="" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-4">
                  <label for="col-form-label" class="col-form-label">Alto (cm)</label>
                </div>
                <div class="col-8">
                  <argon-input id="height" v-model.number="model.default_package.height" 
            :error="v$.default_package.height.$error"
                    :is-required="true" type="number" placeholder="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <argon-loading-button
            v-if="readonly"
            color="primary"
            class="me-4"
            variant="outline"
            size="sm"
            :label="disableSyncButton ? 'Iniciando...' : 'Sincronizar productos'"
            :loading="disableSyncButton"
            :disable-button="disableSyncButton"
            @click="syncShop"
          />
          <ShopCheckConnection :disable-button="disablePingButton" @ping-store="pingStore" />
          <argon-loading-button
            v-if="readonly"
            color="primary"
            variant="gradient"
            size="sm"
            :label="disableButton ? 'Guardando...' : 'Guardar'"
            :loading="disableButton"
            :disable-button="disableButton"
            @click="saveItem"
          >
          </argon-loading-button>
          <argon-loading-button
            v-if="!readonly"
            color="primary"
            variant="gradient"
            size="sm"
            :label="disableButton ? 'Activando...' : 'Confirmar'"
            :loading="disableButton"
            :disable-button="disableButton || disablePingButton"
            @click="saveItem"
          >
          </argon-loading-button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
<style scoped>
.sup-question {
    font-size: 1.0em;
}

.spinner-size {
  width: 26px !important;
  height: 26px !important;
}

.text-primary {
  color: linear-gradient(310deg, #ea7c07 0%, #fa8f1d 100%);
}
</style>
