<script setup>
import ArgonHeader from "@/components/table/ArgonHeader.vue";
import { onMounted } from "vue";
import { computed, watch, ref } from "vue";
import { userStore } from "@/_store/userStore";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const emit = defineEmits([
  "onReload",
  "pageChanged",
  "inputValues",
  "errorValidations"
]);
const tableContainer = ref();
const userStores = userStore();
const genericTable = ref();

const props = defineProps({
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  showInputs: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Object,
    default() {
      return {
        total: 0,
        data: [],
      };
    },
  },
  headers: {
    type: Array,
    default() {
      return [];
    },
  },
  showPagination: {
    type: Boolean,
    default: false,
  },
});

let dataInputs = ref({
  postal_code: "",
  cutoff_time: ""
});

const rules = {
  postal_code: {
    required: helpers.withMessage("Código postal es requerido", required)
  },
  cutoff_time: {
    required: helpers.withMessage("Hora de corte es requerida", required)
  },
};
const v$ = useVuelidate(rules, dataInputs);

async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
function displaySpinner(show = false) {
  if (show) {
    let container = tableContainer.value
    let table = genericTable.value
    table.classList.add("overflow-hidden")
    container.classList.add('loading')
    container.style.setProperty('--size', '40px 40px');
    if (props.items.data.length <= 2) {
      container.style.setProperty('--top-position', '40%');
    } else {
      container.style.setProperty('--top-position', '0');
    }
    return
  }

  let container = tableContainer.value
  container.classList.remove('loading')


  let table = genericTable.value
  table.classList.remove("overflow-hidden")
}
async function agregarExcepcion() {
  const validation = await validate();
  const hasErrors = Object.keys(validation).length > 0;

  if (hasErrors) {
    let message = "";
    for (const value of validation) {
      message = `${message}<li>${value.$message}</li>`;
    }
    emit("errorValidations", message);
    return; // Stop propagation if there are errors
  }

  emit("inputValues", dataInputs.value);
  dataInputs.value = {
    postal_code: "",
    cutoff_time: "",
  };
  v$.value.$reset();
}

watch(
  () => props.items,
  () => {
    displaySpinner();
  }
);

onMounted(() => { });
const isAgency = computed(() => userStores.userSigned?.portalType === 'AGENCY');
</script>
<template>
  <div class="p-0">
    <div id="simple-table-container" :class="isAgency ? 'agency' : 'customer'" class="col-md-auto loading"
      ref="tableContainer">
      <div id="simple-table" ref="genericTable">
        <table class="table align-items-center mb-0">
          <thead class="sticky-top bg-white">
            <tr>
              <argon-header :headers="headers"></argon-header>
            </tr>
            <tr v-if="showInputs">
              <th>
                <argon-input class="size-input" :error="v$.postal_code.$error" :max-length="5" v-model="dataInputs.postal_code" />
              </th>
              <th>
                <argon-input class="size-input" :error="v$.cutoff_time.$error" type="time" v-model="dataInputs.cutoff_time" />
              </th>
              <th class="d-flex justify-content-center align-items-center">
                <argon-button @click="agregarExcepcion" size="xs" color="primary" style="width: 70px;"
                  class="size-button d-flex justify-content-center align-items-center">+</argon-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.data" :key="item._id">
              <slot :item="item"></slot>
            </tr>
          </tbody>
          <tfoot class="text-end">
            <slot name="foot-table"></slot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.size-input {
  width: 100px;
}
.size-button {
  height: 20px !important;
  width: 20px !important;
  margin-top: 4px !important;
}
</style>
<style lang="scss" scoped>
.input-no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}

thead th {
  padding: 0px !important;
  border-bottom: none !important;
}
thead tr:nth-child(2) th {
  border-bottom: none !important;
  border-top: none !important;
}

.color {
  color: #8091C5 !important;
}

#simple-table {
  max-height: 510px;
}

.overflow-hidden {
  overflow: hidden;
}

#simple-table-container.loading #simple-table {
  position: relative;
  border: none;
}

#simple-table-container.agency.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-agency.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}

#simple-table-container.customer.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-customer.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}

.card-detail-layout .table tfoot {
  border-color: #dee2e6 !important;
}
</style>
