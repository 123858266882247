export const estados = [
  {
    label: "Pendiente",
    value: "PENDIENTE",
  },
  {
    label: "En curso",
    value: "EN_CURSO",
  },
  {
    label: "Finalizado",
    value: "FINALIZADO",
  },
  {
    label: "Incidencia",
    value: "INCIDENCIA",
  },
  {
    label: "Anulado",
    value: "ANULADO",
  },
];

export function getStatusLabel(value) {
  const statusObject = estados.find((item) => item.value === value);
  return statusObject ? statusObject.label : value;
}
