<script setup>
import { onMounted, ref, watch, computed } from "vue";

import ArgonInput from "@/components/ArgonInput";
import ArgonCheckbox from "@/components/ArgonCheckbox";

let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  inputsSize: {
    type: String,
    default: "default",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabledReturn: {
    type: Boolean,
    default: false,
  },
});

let model = ref({
  refund_amount: "",
  insurance: "",
  delivery_on_saturday: false,
  returns: false,
});

let key = ref(1);
onMounted(() => {
  model.value = props.modelValue;

  document
    .querySelector("#fulfillmentModal")
    .addEventListener("show.bs.modal", () => {
      model.value = props.modelValue;
      key.value += 1;
    });
});
const tooltipText = computed(() => {
  if (props.disabledReturn) return 'Dado el servicio no es posible marcar la prestación.'
  return null;
});
watch(
  () => props.disabledReturn,
  (newVal) => {
    if (newVal === true) {
      model.value.returns = false;
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="row">
    <div class="col-lg-3">
      <div class="row">
        <div class="col-6">
          <label class="col-form-label">Reembolso</label>
        </div>
        <div class="col-6">
          <argon-input
            id="refund_amount"
            :key="key"
            v-model.number="model.refund_amount"
            type="number"
            placeholder=""
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="row">
        <div class="col-5">
          <label class="col-form-label">Seguro</label>
        </div>
        <div class="col-7">
          <argon-input
            id="insurance"
            :key="key"
            v-model.number="model.insurance"
            type="number"
            placeholder=""
            class="w-100"
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="row">
        <div class="col-5 pe-0">
          <label class="col-form-label">Sábado</label>
        </div>
        <div class="col-7 text-start">
          <argon-checkbox
            id="delivery_on_saturday"
            :key="key"
            v-model.number="model.delivery_on_saturday"
            name="delivery_on_saturday"
            class="ms-4"
            :disabled="readonly"
          ></argon-checkbox>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="row">
          <div class="col-5">
            <label class="col-form-label">Retorno</label>
          </div>
          <div class="col-7">
            <argon-checkbox
              v-if="!disabledReturn"
              id="returns"
              :key="key"
              v-model.number="model.returns"
              name="returns"
              class="ms-4"
              :disabled="disabledReturn"
            ></argon-checkbox>
            <argon-checkbox v-else v-tooltip :data-bs-title="tooltipText" style="width: 20px;" id="returns" v-model.number="model.returns"
              name="returns" class="ms-4" :disabled="disabledReturn"></argon-checkbox>
          </div>
        </div>
    </div>
  </div>
</template>
