<script setup>
import ArgonHeader from "@/components/table/ArgonHeader.vue";
import { onMounted } from "vue";
import { argonTableStore } from "@/_store/argonTableStore";
import { computed, watch, ref } from "vue";
import { userStore } from "@/_store/userStore";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const emit = defineEmits([
  "onReload",
  "pageChanged",
  "inputValues"
]);

const tableStore = argonTableStore();
const tableContainer = ref();
const userStores = userStore();
const genericTable = ref();

const props = defineProps({
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  showInputs:{
    type: Boolean,
    default: false,
  },
  items: {
    type: Object,
    default() {
      return {
        total: 0,
        data: [],
      };
    },
  },
  headers: {
    type: Array,
    default() {
      return [];
    },
  },
  showPagination: {
    type: Boolean,
    default: false,
  },
});

let dataInputs = {
  postal_code: "",
  cutoff_time: ""
}

function onReload() {
  displaySpinner(true);
  emit("onReload");
}

const nextPage = computed(() => {
  const maxPages = Math.ceil(props.items.total / 20);
  if (tableStore.pagination.page == maxPages) {
    return 1;
  }
  return tableStore.pagination.page + 1;
});

const previousPage = computed(() => {
  const maxPages = Math.ceil(props.items.total / 20);
  if (tableStore.pagination.page === 1) {
    return maxPages;
  }
  return tableStore.pagination.page - 1;
});

const pagination_description = computed(() => {
  const startPage = (tableStore.pagination.page - 1) * 20 + 1;
  let defaultTotalPage=props.items.total ? 20:0;
  const endPage = startPage + defaultTotalPage - 1;
  return `${startPage}-${endPage > props.items.total ? props.items.total : endPage}`;
});

function displaySpinner(show=false) {
  if (show) {
    let container = tableContainer.value
    let table = genericTable.value
    table.classList.add("overflow-hidden")
    container.classList.add('loading')
    container.style.setProperty('--size', '40px 40px');
    if (props.items.data.length <= 2 ) {
      container.style.setProperty('--top-position', '40%');
    }else{
      container.style.setProperty('--top-position', '0');
    }
    return
  }

  let container = tableContainer.value
    container.classList.remove('loading')


  let table = genericTable.value
  table.classList.remove("overflow-hidden")
}

function changePage(newPage) {
  tableStore.pagination.page = newPage;
  displaySpinner(true);
  emit("pageChanged", newPage);
}
function agregarExcepcion(){
  emit("inputValues", dataInputs);
}

watch(
  () => props.items,
  () => {
    displaySpinner();
  }
);

onMounted(() => {});
const isAgency = computed(() => userStores.userSigned?.portalType==='AGENCY');
</script>

<template>
  <div class="p-0">
    <div id="simple-table-container" :class="isAgency? 'agency':'customer'" class="col-md-auto loading" ref="tableContainer">
      <nav v-if="showPagination" aria-label="paginacion" class="d-flex justify-content-end align-items-center">
        <ul class="pagination m-0 pe-1">
          <li class="page-item">
            <a v-tooltip data-bs-title="Refrescar" class="page-link" @click="onReload">
              &#8634;
            </a>
          </li>
        </ul>
        <ul class="m-0 ps-1 pe-1">
          <span class="page color text-xs">{{ pagination_description }} / {{ items.total }}</span>
        </ul>
        <ul v-tooltip class="pagination justify-content-end m-0">
          <li class="page-item">
            <a class="page-link" @click="changePage(previousPage)">&lt;</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click="changePage(nextPage)">&gt;</a>
          </li>
        </ul>
      </nav>
      <div id="simple-table" ref="genericTable">
        <table class="table align-items-center mb-0">
          <thead class="sticky-top bg-white">
            <tr>
              <argon-header :headers="headers"></argon-header>
            </tr>
            <tr v-if="showInputs">
              <th>
                <argon-input :max-length="5" type="number" v-model="dataInputs.postal_code" />
              </th>
              <th>
                <argon-input type="time" v-model="dataInputs.cutoff_time" />
              </th>
              <th>
                <argon-button @click="agregarExcepcion" size="xs" color="primary" class="d-flex justify-content-center align-items-center">+</argon-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.data" :key="item._id">
              <slot :item="item"></slot>
            </tr>
          </tbody>
          <tfoot class="text-end">
            <slot name="foot-table"></slot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.color {
  color: #8091C5 !important;
}
#simple-table {
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

#simple-table-container.loading #simple-table {
  position: relative;
}

#simple-table-container.agency.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-agency.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}
#simple-table-container.customer.loading #simple-table:after {
  position: absolute;
  top: var(--top-position);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.046);
  background-image: url('../../assets/img/loading-customer.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--size);
  content: "";
}
.card-detail-layout .table tfoot{
  border-color: #dee2e6 !important;
}
</style>
