<script setup>
import ArgonInput from "@/components/ArgonInput.vue";
import AutoComplete from "@/components/auto-complete/AutoComplete.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import { onMounted, ref, watch } from "vue";
import { contactListStore } from "@/_store/contactStore";
import { contactDetailStore } from "@/_store/contactDetailStore";
const emit = defineEmits(["selectedContact", "update:modelValue"]);

let model = ref({
  name: "",
  attention_of: "",
  vat_number: "",
  email: "",
  phone: "",
});
let props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      name: "",
      attention_of: "",
      vat_number: "",
      email: "",
      phone: "",
    }),
  },
  type: {
    type: String,
    default: "",
  },
  customerId: {
    type: String,
    default: "",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});
onMounted(() => {
  Object.assign(model.value, props.modelValue);
  //model.value = props.modelValue;
  idContact.value = generateUniqueId();
});
let pagination=false;
function getContact(text,page) {
  if(page==1){
    pagination=true;
  }else pagination =false;
  if (props.customerId) {
    let req = text !== "" ? { type: props.type,
            $or: [
                { "contact.name": { $regex: text, $options: "i" } },
                { "reference": { $regex: text, $options: "i" } }
            ] } : { type: props.type };
    contactStore.getAllContactsByCustomer(props.customerId, req,{ page: page, limit: 80 });
  }
}
function generateUniqueId() {
  return "contact-" + Math.random().toString(36).substr(2, 9);
}
const rules = {
  name: { required: helpers.withMessage("Nombre es requerido", required) },
  email: { email: helpers.withMessage("Ingrese un email valido", email) },
};
const contactStore = contactListStore();
const idContact = ref(generateUniqueId());
const contactSummaries = contactDetailStore();

const v$ = ref(useVuelidate(rules, model));

const dataSuggestions = ref([]);
async function validate() {
  await v$.value.$validate();
  return v$.value.$errors;
}
function selectedAutocomplete(item) {
  if(item._id){
    model.value.name = item.contact.name;
    contactSummaries.getContactById(item._id);
  }
}

watch(
  () => contactSummaries.contact,
  (value) => {
    if (!value) return;
    if (value.type != props.type) return;
    emit("selectedContact", value);
  }
);
watch(
  () => props.customerId,
  (value) => {
    if (!value){
      contactStore.clearCustomerContacts();
    }
  }
);
watch(
  () => model.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true }
);

watch(
  () => contactStore.customerContacts,
  (value) => {
    dataSuggestions.value =[];
    if(value && value.data.length > 0){
      if(pagination)
      dataSuggestions.value = [...dataSuggestions.value, ...value.data];
    }
  },
);
defineExpose({
  validate,
});
</script>
<template>
  <div class="row">
    <div class="col-3">
      <label class="col-form-label required">Nombre</label>
    </div>
    <div class="col-9">
      <auto-complete
        v-model="model.name"
        :max-length="40"
        :is-required="true"
        :error="v$.name.$error"
        type="text"
        placeholder="Nombre"
        :data-suggestions="dataSuggestions"
        :item-text="['contact.name','reference']"
        separator=" - "
        size="default"
        :disabled="readonly"
        @text-changed="getContact"
        @page-changed="getContact"
        @selected-change="selectedAutocomplete"
        menu-width="500"
      ></auto-complete>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <label class="col-form-label">Atención de</label>
        </div>
        <div class="col-6">
          <argon-input
            id="attention_of"
            v-model="model.attention_of"
            :max-length="75"
            placeholder=""
            name="attention_of"
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Id fiscal</label>
        </div>
        <div class="col-8">
          <argon-input
            id="text"
            v-model="model.vat_number"
            :max-length="25"
            type="vat_number"
            placeholder=""
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <label class="col-form-label">Teléfono</label>
        </div>
        <div class="col-6">
          <argon-input
            id="phone"
            v-model="model.phone"
            :max-length="15"
            type="text"
            placeholder=""
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-4">
          <label class="col-form-label">Email</label>
        </div>
        <div class="col-8">
          <argon-input
            id="email"
            v-model="model.email"
            :error="v$.email.$error"
            :max-length="75"
            type="email"
            placeholder=""
            :disabled="readonly"
          />
        </div>
      </div>
    </div>
  </div>
</template>
