<script setup>
import DropZone from "/src/components/dropzone/DropZone.vue";
import FileView from "/src/components/dropzone/FileView.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { dropzoneStore } from "/src/components/dropzone/dropzoneStore.js";
import { ref, computed, watch, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { ArgonLoadingButton } from "@/components";
import { dateUtils } from "@/utils";
import { useStore } from "vuex";

const emits = defineEmits(['close', 'success']);
const dropStore = dropzoneStore();
const store = useStore();
const bootstrap = store.state.bootstrap;
const importNewPricingFares = ref(null);
const snackbar = ref(null);

const data = ref({
  date: null,
});

const rules = {
  date: { required: helpers.withMessage("Ingrese una fecha valida", required) },
};

const v$ = useVuelidate(rules, data);

onMounted(() => {
  dropStore.clearFiles();
  let myModal = new bootstrap.Modal(document.getElementById("modalNewPricingFares"), {
    keyboard: true,
  });
  myModal.show();
}),

  function onInputChange(e) {
    dropStore.addFiles(e.target.files);
    e.target.value = null;
  }

function removeFile(file) {
  dropStore.removeFile(file);
}

function validateFile() {
  return dropStore.files.length > 0;
}

async function upload() {
  await v$.value.$validate();
  let message = "";
  if (v$.value.$error) {
    message = `${message}<li>Debe seleccionar una fecha válida</li>`;
  }
  if (!validateFile()) {
    message = `${message}<li>Debe seleccionar un fichero</li>`;
  }
  if (message != "") {
    if (!validateFile()) {
      let idDropZone = document.getElementById("idDropZonePricingFares");
      if (idDropZone) {
        if (!idDropZone.classList.contains("is-invalid")) {
          idDropZone.classList.add("is-invalid");
        }
      }
    }
    snackbar.value = {
      title: "Error",
      message: message,
      type: "danger",
      color: "white",
    };
    return;
  }
  const body = {
    date: dateUtils.add(data.value.date, 'hours', 12)
  };
  dropStore.uploadFiles("/pricing/fare/price/batch", body);
}

function onClick() {
  let idDropZone = document.getElementById("idDropZonePricingFares");
  if (idDropZone && idDropZone.classList.contains("is-invalid")) {
    idDropZone.classList.remove("is-invalid");
  }
}
function closeModal() {
  emits('close')
}

watch(
  () => dropStore.uploadStatus,
  (newSelection) => {
    if (newSelection === "SUCCESS") {
      emits('success')
      importNewPricingFares.value.closeModal();
    }
  }
);
watch(
  () => dropStore.error,
  () => {
    snackbar.value = {
      title: "Error",
      message: dropStore.error.message,
      type: "danger",
      color: "white",
    };
  }
);

const disableButton = computed({
  get() {
    return dropStore.disableButton;
  },
});

const closeSnackbar = () => {
  snackbar.value = null;
};
</script>

<template>
  <modal-base id="modalNewPricingFares" ref="importNewPricingFares" @hide="closeModal">
    <template #header>
      <h6 id="ModalLabel" class="modal-title">
        Importar
      </h6>
    </template>
    <template #body>
      <form class="border-0" id="formData">
        <div>
          <div class="row">
            <div class="col-4">
              <label for="inputPassword6" class="col-form-label">Inicio vigencia</label>
            </div>
            <div class="col-8">
              <argon-input id="date" :error="v$.date.$error" v-model="data.date" type="date" placeholder="" required />
            </div>
          </div>
        </div>
        <DropZone @click="onClick" id="idDropZonePricingFares" v-slot="{ dropZoneActive }"
          class="drop-area radius drop-zone-area p-0" @files-dropped="dropStore.addFiles">
          <label for="file-input" class="file-input-label">
            <span v-if="dropZoneActive" class="file-input-text">
              <i class="fa fa-upload"></i>
              <span>Soltar sus archivos aquí</span>
            </span>
            <span v-else class="file-input-text">
              <span>Arrastre sus archivos o pulsa aquí para seleccionarlos</span>
            </span>
          </label>
          <input id="file-input" type="file" multiple @change="onInputChange" />
        </DropZone>
        <file-view :files="dropStore.files" @remove="removeFile"></file-view>
      </form>
    </template>
    <template #footer>
      <argon-loading-button class="btn bg-gradient-primary btn-sm" color="primary" variant="gradient"
        :label="disableButton ? 'Importando...' : 'Importar'" :loading="disableButton" :disable-button="disableButton"
        @click="upload" />
    </template>

    <div class="position-fixed top-0 end-0 z-index-2 p-3">
      <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
        :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
        :close-handler="closeSnackbar" />
    </div>
  </modal-base>
</template>
