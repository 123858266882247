<script setup>
import { onMounted, watch, ref } from "vue";
import Direction from "@/components/forms/Direction.vue";
import Horario from "@/components/forms/Horario.vue";
import Tracking from "@/components/StopTracking.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import TabsBootstrap from "@/components/TabsBootstrap.vue"
import ArgonDetailLayout from "@/components/detail/ArgonDetailLayout";
import { useRoute } from "vue-router";
import {stopListStore, stopDetailStore } from "@/_store";
import PartnerAvatar from "@/components/PartnerAvatar.vue";
import StatusBadge from "@/components/badges/StopStatus.vue";
import { dateUtils } from "@/utils";
import StopListShipment from '@/components/StopListShipment.vue'

import { useStore } from "vuex";
import ModalChangeStatus from "./ModalChangeStatus.vue";

const props = defineProps({
  showChangeStatus: {
    type: Boolean,
    defailt: false,
  }
});
const route = useRoute();
const detailStore = stopDetailStore();
const stopStore=stopListStore();
let tabSelected = ref("envios");
const tabs = [
  { label: "Envíos", value: "envios" },
  { label: "Seguimiento", value: "seguimiento" },
];
const store = useStore();
let statusModal=null;
const bootstrap = store.state.bootstrap;

let localValue = ref({
  date: dateUtils.formatDate(dateUtils.getToday(), "YYYY-MM-DD"),
  customer: {
    code:'',
    contact: {
      name: "",
      vat_number: "",
      email: "",
      attention_of: "",
      phone: "",
    },
    address: {
      postal_code: "",
      street: "",
      street2: "",
      city: "",
      country: "ES",
      location: null,
      text: "",
      place_id: "",
      normalized: true,
      province: "",
    }
  },
  status_description: "",
  schedules: {
      first_frequency: {
        initial: "",
        final: "",
      },
      second_frequency: {
        initial: "",
        final: "",
      },
    },
});
const actions = ref([
  [
    { label: "Etiquetas", event: "onLabelPrint" },
  ],
  [
    { label: "Exportar Envíos", event: "onExportReport" }
  ]
  

]);
const events = {
  onLabelPrint: getShipmentsLabel,
  onExportReport: exportData,
  onChangeStatus: changeStatus,
  onGetManifest: getManifest
};
function changeStatus() {
    statusModal = new bootstrap.Modal(document.getElementById("modalChangeStopStatus"), {
    keyboard: true,
  });
  statusModal.show();
}
onMounted(() => {
  loadStop();
});

function triggerEvent(action) {
  try {
    events[action.event]();
  } catch {
    console.log("Evento no implementado");
  }
}
function getShipmentsLabel() {
  stopStore.getStopShipmentLabel(route.params.id);
}
function getManifest() {
  stopStore.getStopManifest(route.params.id);
}
function exportData() {
  stopStore.getStopShipmentAsExcel(route.params.id);
}

function loadStop() {
  if (props.showChangeStatus){
    actions.value.push([{label: "Transitar Estado", event: "onChangeStatus" }])
  }
  if (route.params.id) {
    detailStore.getStopById(route.params.id);
  }
  if (route.path.includes('/pickup/others') || route.path.includes('/pickup/own')) {
    actions.value[1].push({label: "Manifiesto", event: "onGetManifest" })
  }
}

function makeExport(blob) {
  let URI = URL.createObjectURL(blob);
  window.open(URI, "_blank");
}

function changedState(state){
  localValue.value.status=state;
}

watch(
  () => detailStore.error,
  (value) => {
    if (value) {
      snackbar.value = {
        title: "Error",
        message: value.message,
        type: "danger",
        color: "white",
      };
    }
  }
);

watch(
  () => detailStore.stopDetail,
  (value) => {
    if (value) {
      localValue.value = value;
      localValue.value.date= dateUtils.formatDate(value.date, "YYYY-MM-DD");
    }
  }
);
watch(
  () => stopStore.stopShipmentLabel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
watch(
  () => stopStore.stopManifest,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);
watch(
  () => stopStore.stopShipmentExcel,
  (newVal) => {
    if (newVal) {
      makeExport(newVal);
    }
  }
);

const snackbar = ref(null);
const closeSnackbar = () => {
  snackbar.value = null;
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <argon-detail-layout :group-actions="actions" @click="triggerEvent">
        <template #buttons>
        </template>
        <template #body>
          <div class="row">
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      Datos Básicos
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5">
                          <div class="row">
                            <div class="col-3">
                              <label for="date" class="col-form-label">Fecha</label>
                            </div>
                            <div class="col-9">
                              <argon-input id="date" v-model="localValue.date" :max-length="10"
                                type="text" placeholder="Código" :disabled="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <div class="row">
                            <div class="col-3">
                              <label for="client-name" class="col-form-label">Cliente</label>
                            </div>
                            <div class="col-9">
                              <argon-input id="client-name" v-model="localValue.customer.contact.name" :max-length="10"
                                type="text" placeholder="Código" :disabled="true" />
                            </div>
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="row">
                            <div class="col-3">
                              <label class="col-form-label">Código</label>
                            </div>
                            <div class="col-9">
                              <argon-input id="code" v-model="localValue.customer.code" :max-length="10"
                                type="text" placeholder="Código" :disabled="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      Contacto
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-7">
                          <div class="row">
                            <div class="col-4">
                              <label for="attention_of" class="col-form-label">Atención de</label>
                            </div>
                            <div class="col-8">
                              <argon-input  id="attention_of"
                                v-model="localValue.customer.contact.attention_of" :max-length="40" type="text"
                                :disabled="true"  />
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="row">
                            <div class="col-4">
                              <label for="id_fiscal" class="col-form-label">Id fiscal</label>
                            </div>
                            <div class="col-8">
                              <argon-input  id="id_fiscal" v-model="localValue.customer.contact.vat_number"
                                :max-length="25" type="vat_number"  :disabled="true"  />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <div class="row">
                            <div class="col-4">
                              <label class="col-form-label">Email</label>
                            </div>
                            <div class="col-8">
                              <argon-input id="email" v-model="localValue.customer.contact.email"
                                :max-length="75" type="email" 
                                class="ms-1" :disabled="true" />
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="row">
                            <div class="col-4">
                              <label for="phone" class="col-form-label">Teléfono</label>
                            </div>
                            <div class="col-8">
                              <argon-input id="phone" v-model="localValue.customer.contact.phone"
                                :max-length="15" type="text"   :disabled="true"  />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6-col-lg-6 col-sm-12">
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Dirección</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <direction ref="directionForm" v-model="localValue.address" :readonly="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row spacing">
                <div class="col">
                  <div class="card">
                    <div class="card-header">Horario</div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                            <horario
                              ref="horarioForm"
                              v-model="localValue.schedules"
                              :readonly="true" 
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #title>
          <div class="row">
            <div class="col-12">
              <argon-input id="number" v-model="localValue.number" :max-length="30" type="text"
                  size="lg" font-style="h4 fw-bold" :disabled="true" />
            </div>
          </div>
        </template>
        <template #state>
          <div class="row">
            <div class="col">
              <partner-avatar :item="localValue.courier"></partner-avatar>
            </div>
            <div class="col">
              <status-badge v-if="localValue.status_description" :item="localValue.status" :tooltip="localValue.status_description"></status-badge>
              <status-badge v-else :item="localValue.status" :tooltip="localValue.errors"></status-badge>
            </div>
          </div>
        </template>
        <template #tabs>
          <div class="card mt-3">
            <div class="card-body">
              <tabs-bootstrap v-model="tabSelected" :tabs="tabs"/>
              <stop-list-shipment v-if="tabSelected === 'envios'" :id-stop="route.params.id" />
              <tracking v-if="tabSelected == 'seguimiento'" :id-stop="route.params.id"
                :courier="localValue.courier" />
            </div>
          </div>
        </template>
      </argon-detail-layout>
    </div>
  </div>
  <modal-change-status id="modalChangeStopStatus" :stop-id="route.params.id" @successChange="changedState"/>
  <div class="position-fixed top-1 end-1 z-index-2">
    <argon-snackbar v-if="snackbar" :title="snackbar.title" :description="snackbar.message"
      :icon="{ component: 'ni ni-notification-70', color: snackbar.type }" :color="snackbar.color"
      :close-handler="closeSnackbar" />
  </div>
</template>
